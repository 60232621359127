import { ColorsPalette } from '../common';
import { RequestStatus, UserRequest } from '../types';

export const requestStatusLabels: Record<UserRequest['status'], string> = {
  [RequestStatus.Approved]: 'Approved',
  [RequestStatus.Pending]: 'Pending',
  [RequestStatus.Declined]: 'Declined'
};

export const requestStatusColors: Record<UserRequest['status'], keyof ColorsPalette> = {
  [RequestStatus.Approved]: 'success',
  [RequestStatus.Pending]: 'warning',
  [RequestStatus.Declined]: 'error'
};
