import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { FormattedDateTimeRange } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { fixedCellSize } from '../../utils';
import { HOVER_BOX_SX, LineClamp, TableLoadingContainer, TransparentChip } from '../common';
import { UserEvent } from '../types';

export interface EventsTableProps {
  events: UserEvent[];
  loading?: boolean;
  detailPath: (eventId: string) => string;
  renderAction?: (event: UserEvent) => React.ReactNode;
}

export const EventsTable = ({ events, detailPath, renderAction, loading }: EventsTableProps) => {
  const navigate = useNavigate();
  return (
    <TableLoadingContainer loading={loading}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={fixedCellSize(140)}>Date</TableCell>
            <TableCell sx={fixedCellSize(140, { padding: 0 })}>Questionary</TableCell>
            <TableCell sx={fixedCellSize(180)}>Name</TableCell>
            <TableCell sx={fixedCellSize(120)}>Place</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody sx={{ height: 1 }}>
          {events.map((event) => {
            const { id: eventId, dateFrom, dateTo, title, place } = event;
            const eventDetailPath = detailPath(eventId);
            return (
              <TableRow key={eventId} onClick={() => navigate(eventDetailPath)} sx={HOVER_BOX_SX}>
                <TableCell sx={fixedCellSize(140)}>
                  <Box color="text.secondary" typography="body2">
                    <FormattedDateTimeRange from={dateFrom} to={dateTo} month="short" day="numeric" year="numeric" />
                  </Box>
                </TableCell>
                <TableCell sx={fixedCellSize(140, { padding: 0 })}>
                  {event.questionnaire ? <TransparentChip label={event.questionnaire.title} /> : null}
                </TableCell>
                <TableCell sx={fixedCellSize(180)}>
                  <LineClamp maxLines={2} sx={{ fontWeight: 'bold' }}>
                    {title}
                  </LineClamp>
                </TableCell>
                <TableCell sx={fixedCellSize(120)}>{place}</TableCell>
                <TableCell sx={{ width: 'auto' }}>
                  <Box display="flex" justifyContent="flex-end">
                    {renderAction?.(event)}
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableLoadingContainer>
  );
};
