import { Box, Card, CardActions, CardContent, CardProps, Typography, TypographyProps } from '@mui/material';
import { isString } from 'lodash';
import { ReactNode } from 'react';
import { FlexBlock } from '../common';

type WidgetSizes = 'small' | 'medium' | 'large';

const sizes: Record<WidgetSizes, number> = {
  small: 250,
  medium: 400,
  large: 550
};

export const WidgetHeader = (props: TypographyProps) => <Typography variant="body2" fontWeight={600} {...props} />;
export interface WidgetLayoutProps extends CardProps {
  header: string | ReactNode;
  actions?: ReactNode;
  size?: WidgetSizes;
}

export const WidgetLayout = ({ header, children, actions, sx = {}, size, ...props }: WidgetLayoutProps) => (
  <Card
    sx={{
      boxShadow: 'rgba(255, 255, 255, 0.02) 0px 2px 8px 0px',
      height: size ? sizes[size] : 1,
      ...sx
    }}
    {...props}
  >
    <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box display="flex" flexDirection="column" gap={2} height={1}>
        {isString(header) ? (
          <WidgetHeader variant="body2" fontWeight={600}>
            {header}
          </WidgetHeader>
        ) : (
          header
        )}
        {children}
      </Box>
      {actions && (
        <>
          <FlexBlock />
          <CardActions sx={{ padding: 0, mt: 3 }}>{actions}</CardActions>
        </>
      )}
    </CardContent>
  </Card>
);
