import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { SortButton } from '../common';
import { FiltersType, SortActions } from '../hooks';
import { RequestStatus, UserRequest } from '../types';
import { requestStatusLabels } from './labels';

interface StatusCheckboxProps {
  status: RequestStatus;
  filters: FiltersType<RequestFiltersType>;
}

const StatusCheckbox = ({ status, filters: { filters, setFilters } }: StatusCheckboxProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [status]: e.target.checked });
  };

  return (
    <FormControlLabel
      control={<Checkbox size="small" checked={filters[status] ?? false} onChange={handleChange} />}
      label={requestStatusLabels[status]}
    />
  );
};

export interface RequestFiltersType {
  [RequestStatus.Approved]?: boolean;
  [RequestStatus.Declined]?: boolean;
  [RequestStatus.Pending]?: boolean;
}

export interface RequestFiltersProps {
  filters: FiltersType<RequestFiltersType>;
  sort: SortActions<UserRequest>;
  disabled?: boolean;
}
export const RequestFilters = ({ sort: { toggleSort, getSortOrder }, filters, disabled }: RequestFiltersProps) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <SortButton onClick={() => toggleSort('createdDate')} direction={getSortOrder('createdDate')} disabled={disabled}>
      Sort by Date
    </SortButton>
    <StatusCheckbox status={RequestStatus.Approved} filters={filters} />
    <StatusCheckbox status={RequestStatus.Pending} filters={filters} />
    <StatusCheckbox status={RequestStatus.Declined} filters={filters} />
  </Stack>
);
