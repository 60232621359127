import { Box, BoxProps } from '@mui/material';
import { DocumentType } from '../types';

const defaultDocumentIcon = '/icons/default.svg';
const documentTypeIconMap: Record<DocumentType, string> = {
  pdf: '/icons/pdf.svg',
  doc: '/icons/doc.svg',
  xls: '/icons/xls.svg',
  ppt: '/icons/ppt.svg',
  image: '/icons/img.svg'
};

export interface DocumentIconProps extends Omit<BoxProps<'img'>, 'component' | 'src'> {
  type?: DocumentType;
}
export const DocumentIcon = ({ type, ...props }: DocumentIconProps) => (
  <Box
    data-type={type}
    component="img"
    src={type ? documentTypeIconMap[type] : defaultDocumentIcon}
    alt={type}
    height="1.5rem"
    {...props}
  />
);
