import { PaginationOptions, paginate } from '@jgereg/paginated';
import { useCallback, useMemo, useState } from 'react';

export const usePagination = <TData>(data: TData[], options: PaginationOptions = { page: 1, pageSize: 10 }) => {
  const [paginationState, setPaginationState] = useState<PaginationOptions>(options);
  const setPage = useCallback(
    (page: PaginationOptions['page']) => setPaginationState((prev) => ({ ...prev, page })),
    [setPaginationState]
  );
  const resetPagination = useCallback(() => setPage(1), [setPage]);
  const { items, pagination } = useMemo(() => paginate<TData>(data)(paginationState), [data, paginationState]);
  return { items, pagination, setPage, resetPagination, paginationState };
};
