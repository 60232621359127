import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { createContext, useContext } from 'react';
import { useCommonApi } from '../../api';
import { User } from '../types';

export type LoginParams = {
  email: string;
  password: string;
};

export type resetPasswordParams = {
  identification: string;
};

export type resetPasswordAction = 'resetPassword' | 'activateAccount' | 'deleteAccount';
export type userActionConfirm = {
  action: resetPasswordAction;
  identification: string;
  confirmCodeKey: string;
  hash: string;
};

interface AuthContextType {
  user: User | null;
  logout: () => void;
  login: (params: LoginParams) => void;
  resetPassword: (params: resetPasswordParams) => void;
  userActionConfirm?: (resetString: userActionConfirm) => void;
  isLoggingIn: boolean;
  isResetingPassword: boolean;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  isLoggingIn: false,
  isResetingPassword: false,
  login: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  userActionConfirm: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { data: user, refetch: fetchUser, isFetching: userLoading } = useSuspenseQuery(useCommonApi().getUser());
  const { mutate: loginUser, isPending } = useMutation(useCommonApi().login());
  const { mutate: resetPassword, isPending: isResetingPassword } = useMutation(useCommonApi().resetPassword());
  const { mutate: userActionConfirm } = useMutation(useCommonApi().userAction());

  const handleLogin = (params: LoginParams) => {
    loginUser(params, {
      onSuccess: () => fetchUser()
    });
  };

  return (
    <AuthContext.Provider
      value={{
        login: handleLogin,
        logout: () => '',
        user,
        isLoggingIn: isPending || userLoading,
        resetPassword,
        isResetingPassword,
        userActionConfirm
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
