import { Button, Stack, StackProps } from '@mui/material';
import { UserQuestionnaire } from '../types';

export interface QuestionnaireActionsProps {
  questionnaire: UserQuestionnaire;
  containerProps?: Partial<StackProps>;
  onShowMyAnswers: () => void;
  onShowOthersAnswers: () => void;
  onFillQuestionaire: () => void;
}

export const QuestionnaireActions = ({
  questionnaire,
  containerProps,
  onShowMyAnswers,
  onShowOthersAnswers,
  onFillQuestionaire
}: QuestionnaireActionsProps) => {
  return (
    <Stack direction="row" spacing={1} {...containerProps}>
      {questionnaire.own ? (
        <Button variant="outlined" color="info" size="small" onClick={() => onShowMyAnswers()}>
          Show my answers
        </Button>
      ) : (
        <Button variant="contained" color="success" size="small" onClick={() => onFillQuestionaire?.()}>
          Fill questionaire
        </Button>
      )}
      {questionnaire.others.length ? (
        <Button variant="contained" color="info" size="small" onClick={() => onShowOthersAnswers()}>
          Show all answers
        </Button>
      ) : undefined}
    </Stack>
  );
};
