import { LoadingButton } from '@mui/lab';
import { Stack, StackProps, SvgIcon, ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { Calender, Check, List, X } from 'untitledui-js-base';
import { useCommonApi } from '../api';
import { EventsViewMode } from '../constants';
import { PageLayout } from './common';
import { NoDataFound } from './common/NoDataFound';
import { EventsTable } from './events';
import { EventStatus, UserEvent } from './types';

interface CalendarListToggleProps extends Omit<ToggleButtonGroupProps, 'value' | 'onChange'> {
  value?: EventsViewMode;
  onChange?: (view: EventsViewMode) => void;
}

const CalendarListToggle = ({ value, onChange, ...props }: CalendarListToggleProps) => (
  <ToggleButtonGroup
    size="medium"
    value={value}
    exclusive
    {...props}
    onChange={(_, view) => onChange?.(view as EventsViewMode)}
  >
    <ToggleButton value="list">
      <SvgIcon sx={{ mr: 1 }}>
        <List />
      </SvgIcon>
      List
    </ToggleButton>
    <ToggleButton value="calendar">
      <SvgIcon sx={{ mr: 1 }}>
        <Calender />
      </SvgIcon>
      Calendar
    </ToggleButton>
  </ToggleButtonGroup>
);

export interface EventActionsProps {
  event: UserEvent;
  onActionSuccess?: () => void;
  containerProps?: Partial<StackProps>;
  loading?: boolean;
}

export const EventActions = ({ event, onActionSuccess, containerProps, loading }: EventActionsProps) => {
  const { mutate: setRequestStatus, isPending } = useMutation(useCommonApi().setEventStatus());

  const decline = () =>
    setRequestStatus({ eventId: event.id, status: EventStatus.Decline }, { onSuccess: onActionSuccess });
  const attend = () =>
    setRequestStatus({ eventId: event.id, status: EventStatus.Attend }, { onSuccess: onActionSuccess });

  const attendDisabled = event.status === EventStatus.Attend;
  const declineDisabled = event.status === EventStatus.Decline;
  return (
    <Stack direction="row" spacing={1} {...containerProps}>
      <LoadingButton
        variant="outlined"
        color="secondary"
        size="small"
        loading={isPending || loading}
        loadingPosition="start"
        sx={{ whiteSpace: 'nowrap' }}
        startIcon={
          <SvgIcon color={attendDisabled ? 'inherit' : 'success'}>
            <Check size="1.5rem" />
          </SvgIcon>
        }
        disabled={attendDisabled}
        onClick={(e) => {
          e.stopPropagation();
          attend();
        }}
      >
        I will go
      </LoadingButton>
      <LoadingButton
        variant="outlined"
        color="secondary"
        size="small"
        loading={isPending || loading}
        loadingPosition="start"
        sx={{ whiteSpace: 'nowrap' }}
        startIcon={
          <SvgIcon color={declineDisabled ? 'inherit' : 'error'}>
            <X size="1.5rem" />
          </SvgIcon>
        }
        disabled={declineDisabled}
        onClick={(e) => {
          e.stopPropagation();
          decline();
        }}
      >
        I will not go
      </LoadingButton>
    </Stack>
  );
};

interface EventsPageProps {
  eventDetailPath: (articleId: string) => string;
}

export const EventsPage = ({ eventDetailPath }: EventsPageProps) => {
  // const [eventsViewMode, setEventsViewMode] = useLocalStorage<EventsViewMode>(LocalStorageKey.EventsViewMode, 'list');
  const { data = [], isPending } = useQuery(useCommonApi().getEvents());

  return (
    <PageLayout
      title="Events"
      withContentBlock
      // extra={<CalendarListToggle value={eventsViewMode} onChange={setEventsViewMode} />}
    >
      {!isEmpty(data) ? (
        <EventsTable
          events={data}
          detailPath={eventDetailPath}
          renderAction={(event) => <EventActions event={event} />}
          loading={isPending}
        />
      ) : (
        <NoDataFound />
      )}
    </PageLayout>
  );
};
