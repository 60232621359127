import { Tooltip, Typography, TypographyProps } from '@mui/material';
import { ReactNode } from 'react';

export interface LineClampProps extends TypographyProps {
  maxLines: number;
  tooltip?: ReactNode | boolean;
}
export const LineClamp = ({ maxLines, tooltip = false, children, sx = {}, ...props }: LineClampProps) => {
  const text = (
    <Typography
      variant="inherit"
      {...props}
      noWrap
      sx={{
        ...sx,
        display: '-webkit-box',
        WebkitLineClamp: maxLines,
        WebkitBoxOrient: 'vertical',
        whiteSpace: 'normal'
      }}
    >
      {children}
    </Typography>
  );
  return tooltip ? <Tooltip title={tooltip === true ? children : tooltip}>{text}</Tooltip> : text;
};
