import { DocumentType } from '../app/types';
import { ApiResponse } from './types';

export interface CustomApiHandlerType<T, R> {
  executed?: (variables: T, data: R) => void;
  failed?: (variables: T, data: R, options: { statusCode: number; message?: string }) => void;
}
export const onSuccessCustomApiResponse =
  <V, R>({ executed, failed }: CustomApiHandlerType<V, R>) =>
  ({ statusCode, success, results, message }: ApiResponse<R>, variables: V) => {
    return success ? executed?.(variables, results) : failed?.(variables, results, { statusCode, message });
  };

export const parseDate = (date: string) => new Date(date);
export const parseId = (id: string | number): string => `${id}`;

export const parseUserName = ({ firstname, lastname }: { firstname?: string; lastname?: string }): string => {
  if (firstname && lastname) return `${firstname} ${lastname}`;
  if (lastname) return lastname;
  if (firstname) return firstname;
  return 'Unknown';
};

type ParsedFile = {
  filename: string;
  data: string; // Base64 encoded file data
};

export const parseFormData = (formData: FormData): Promise<ParsedFile[]> => {
  const promises: Promise<ParsedFile>[] = [];

  formData.forEach((file) => {
    if (file instanceof File) {
      const reader = new FileReader();
      const promise = new Promise<ParsedFile>((resolve, reject) => {
        reader.onload = (event) => {
          const result = (event.target as FileReader).result;
          if (result) {
            resolve({
              filename: file.name,
              data: result as string
            });
          } else {
            reject(new Error('FileReader result is null'));
          }
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
      promises.push(promise);
    }
  });

  return Promise.all(promises);
};

const stringContainsAny = (target: string) => (substrings: string[]) =>
  substrings.some((substring) => target.includes(substring));

export const parseDocumentType = (mimeType: string): DocumentType | undefined => {
  const contains = stringContainsAny(mimeType);
  if (contains(['pdf'])) return DocumentType.PDF;
  if (contains(['document', 'msword', 'doc'])) return DocumentType.DOC;
  if (contains(['excel', 'sheet', 'xls'])) return DocumentType.XLS;
  if (contains(['powerpoint', 'presentation', 'slideshow', 'ppt'])) return DocumentType.PPT;
  if (contains(['image', 'png', 'jpg', 'jpeg', 'webp', 'gif'])) return DocumentType.Image;
  return undefined;
};
