import { Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useCommonApi } from '../api';
import { PageLayout } from './common';
import {
  QuestionnaireActions,
  QuestionnaireModal,
  QuestionnaireModalMode,
  QuestionnariesTable
} from './questionnaries';
import { UserQuestionnaire } from './types';

export const QuestionnariesPage = () => {
  const { data: questionaries = [], isPending } = useQuery(useCommonApi().getQuestionnaries());

  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<
    { questionnaire: UserQuestionnaire; mode: QuestionnaireModalMode } | undefined
  >(undefined);

  return (
    <>
      <PageLayout title="Questionnaries" withContentBlock>
        <Stack spacing={2} height={1}>
          <QuestionnariesTable
            loading={isPending}
            questionnaries={questionaries}
            renderAction={(questionnaire) => (
              <QuestionnaireActions
                questionnaire={questionnaire}
                onShowMyAnswers={() => setSelectedQuestionnaire({ questionnaire, mode: 'showMine' })}
                onShowOthersAnswers={() => setSelectedQuestionnaire({ questionnaire, mode: 'showOthers' })}
                onFillQuestionaire={() => setSelectedQuestionnaire({ questionnaire, mode: 'fill' })}
              />
            )}
          />
        </Stack>
      </PageLayout>
      <QuestionnaireModal
        questionnarie={selectedQuestionnaire?.questionnaire}
        mode={selectedQuestionnaire?.mode}
        handleClose={() => setSelectedQuestionnaire(undefined)}
      />
    </>
  );
};
