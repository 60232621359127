import {
  Box,
  BoxProps,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormControlProps,
  FormGroup,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Select,
  TextField
} from '@mui/material';
import { forwardRef, useCallback } from 'react';
import { Control, Controller, ControllerProps, DefaultValues, FieldValues, useForm } from 'react-hook-form';
import { CheckboxGroupFormFieldType, FormFieldBaseType, RadioGroupFormFieldType, SelectFormFieldType } from './types';
import {
  isCheckboxGroupFormField,
  isEmailFormField,
  isInputFormField,
  isPhoneNumberFormField,
  isRadioGroupFormField,
  isSelectFormField,
  isTextAreaFormField,
  validationPattern
} from './utils';

interface SelectFormFieldProps extends Omit<FormControlProps, 'onChange'> {
  formField: Omit<SelectFormFieldType, 'element'>;
  value: string;
  onChange: (value: string) => void;
}

const SelectGroupFormField = forwardRef<HTMLDivElement, SelectFormFieldProps>(
  ({ formField: { label, options }, onChange, value, ...formControlProps }, ref) => (
    <FormControl ref={ref} {...formControlProps}>
      <FormLabel>{label}</FormLabel>
      <Select
        value={value}
        onChange={(event) => {
          onChange(event.target.value as string);
        }}
        sx={{ backgroundColor: 'background.default' }}
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
);

interface CheckboxGroupFormFieldProps extends Omit<FormControlProps, 'onChange'> {
  formField: Omit<CheckboxGroupFormFieldType, 'element'>;
  value: Record<string, boolean>;
  onChange: (value: Record<string, boolean>) => void;
  direction?: 'row' | 'column';
}

const CheckboxGroupFormField = forwardRef<HTMLDivElement, CheckboxGroupFormFieldProps>(
  ({ formField: { label, options }, onChange, value, direction = 'row', ...formControlProps }, ref) => (
    <FormControl ref={ref} {...formControlProps}>
      <FormLabel>{label}</FormLabel>
      <FormGroup
        onChange={({ target }) => {
          const { name, checked } = target as any;
          onChange({ ...value, [name]: checked });
        }}
        sx={{ display: 'flex', flexDirection: direction }}
      >
        {options?.map((option) => (
          <FormControlLabel
            key={option.value}
            control={<Checkbox value={option.value} name={option.value} />}
            label={option.label}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
);

interface RadioGroupFormFieldProps extends FormControlProps {
  formField: Omit<RadioGroupFormFieldType, 'element'> & RadioGroupProps;
  direction?: 'row' | 'column';
}

const RadioGroupFormField = forwardRef<HTMLDivElement, RadioGroupFormFieldProps>(
  ({ formField: { label, options, ...radioGroupProps }, direction = 'row', ...formControlProps }, ref) => (
    <FormControl ref={ref} {...formControlProps}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup {...radioGroupProps} sx={{ display: 'flex', flexDirection: direction }}>
        {options?.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio value={option.value} />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
);
interface DynamicFormControlerProps<T extends FieldValues = FieldValues> extends Omit<ControllerProps<T>, 'name'> {
  field: FormFieldBaseType;
}
const DynamicFormControler = ({ control, field, rules, ...props }: DynamicFormControlerProps) => (
  <Controller
    key={field.id}
    name={field.name}
    control={control}
    defaultValue={field.defaultValue}
    rules={{ required: field.validation?.required, ...rules }}
    {...props}
  />
);

interface ControlledInputFormFieldProps {
  field: FormFieldBaseType;
  control: Control<any>;
  disabled?: boolean;
}
export const ControlledInputFormField = ({
  control,
  field: fieldFromProps,
  disabled
}: ControlledInputFormFieldProps) => {
  const getValidationPattern = () => {
    if (isEmailFormField(fieldFromProps)) {
      return validationPattern.email;
    }
    if (isPhoneNumberFormField(fieldFromProps)) {
      return validationPattern.phone;
    }
    return undefined;
  };

  const render = useCallback<ControllerProps['render']>(
    ({ field, fieldState }) => {
      if (isRadioGroupFormField(fieldFromProps)) {
        return (
          <RadioGroupFormField
            formField={fieldFromProps}
            {...field}
            color="secondary"
            error={Boolean(fieldState.error)}
            direction={fieldFromProps.options.length > 4 ? 'column' : 'row'}
            disabled={disabled}
          />
        );
      }

      if (isCheckboxGroupFormField(fieldFromProps)) {
        return (
          <CheckboxGroupFormField
            formField={fieldFromProps}
            {...field}
            color="secondary"
            error={Boolean(fieldState.error)}
            direction={fieldFromProps.options.length > 4 ? 'column' : 'row'}
            disabled={disabled}
          />
        );
      }
      if (isTextAreaFormField(fieldFromProps)) {
        return (
          <TextField
            sx={{ backgroundColor: 'background.default' }}
            label={fieldFromProps.label}
            type={fieldFromProps.type}
            placeholder={fieldFromProps.placeholder}
            fullWidth
            {...field}
            color="secondary"
            error={Boolean(fieldState.error)}
            multiline
            minRows={3}
            disabled={disabled}
          />
        );
      }
      if (isInputFormField(fieldFromProps)) {
        return (
          <TextField
            label={fieldFromProps.label}
            type={fieldFromProps.type}
            placeholder={fieldFromProps.placeholder}
            fullWidth
            InputProps={{ sx: { backgroundColor: 'background.default' } }}
            {...field}
            color="secondary"
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
            disabled={disabled}
          />
        );
      }

      if (isSelectFormField(fieldFromProps)) {
        return (
          <SelectGroupFormField
            formField={fieldFromProps}
            {...field}
            color="secondary"
            error={Boolean(fieldState.error)}
            disabled={disabled}
          />
        );
      }
      return <></>;
    },
    [fieldFromProps]
  );

  return (
    <DynamicFormControler
      field={fieldFromProps}
      control={control}
      render={render}
      rules={{ pattern: getValidationPattern() }}
      disabled={disabled}
    />
  );
};

export interface DynamicFormRendererProps<FormData extends FieldValues = FieldValues>
  extends Omit<BoxProps<'form'>, 'onSubmit' | 'children' | 'defaultValue' | 'onChange'> {
  defaultValues?: DefaultValues<FormData>;
  onSubmit: (data: FormData) => void;
  children: (data: { control: Control<FormData> }) => React.ReactNode;
  onChange?: (change: FormData) => void;
}

export const DynamicFormRenderer = <FormData extends FieldValues = FieldValues>({
  defaultValues,
  onSubmit,
  children,
  ...props
}: DynamicFormRendererProps<FormData>) => {
  const { control, handleSubmit } = useForm<FormData>({
    defaultValues,
    shouldUnregister: true
  });
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate {...props}>
      {children({ control })}
    </Box>
  );
};
