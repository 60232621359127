import { Pagination, Stack, StackProps } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useCommonApi } from '../api';
import { ArticleFilters, ArticlesTable } from './articles';
import { FlexBlock, PageLayout, TransparentChip } from './common';
import { NoDataFound } from './common/NoDataFound';
import { useArticlesFilter } from './hooks';
import { Article } from './types';

export interface ArticleTagsProps extends StackProps {
  article: Pick<Article, 'isNew' | 'isMandatory'>;
}

export const ArticleTags = ({ article: { isNew, isMandatory }, ...props }: ArticleTagsProps) => (
  <Stack direction="row" spacing={2} alignItems="center" {...props}>
    {isMandatory && <TransparentChip size="small" label="Mandatory" color="error" variant="filled" />}
    {isNew && <TransparentChip size="small" label="New" color="success" variant="filled" />}
  </Stack>
);

interface ArticlesPageProps {
  articleDetailPath: (articleId: string) => string;
}

export const ArticlesPage = ({ articleDetailPath }: ArticlesPageProps) => {
  const { data = [], isPending } = useQuery(useCommonApi().getArticles());
  const { items: articles, pagination, filters, sort } = useArticlesFilter(data);

  return (
    <PageLayout title="Articles" withContentBlock>
      <Stack spacing={2} height={1}>
        <ArticleFilters filters={filters} sort={sort} disabled={isPending} />
        {articles.length || isPending ? (
          <ArticlesTable articles={articles} detailPath={articleDetailPath} loading={isPending} />
        ) : (
          <NoDataFound />
        )}
        <FlexBlock />
        {pagination.totalPages > 1 && (
          <Pagination
            count={pagination.totalPages}
            page={pagination.page}
            variant="outlined"
            shape="rounded"
            onChange={(_, page) => pagination.setPage(page)}
          />
        )}
      </Stack>
    </PageLayout>
  );
};
