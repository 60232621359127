import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Container,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  TextFieldProps,
  Typography
} from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import { Control, Controller, FieldErrors, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { LocalStorageKey } from '../constants';
import { BTLNetHeaderTitle } from './BTLNetApp';
import { APP_HEADER_HEIGHT, FlexBlock, FlexCenter } from './common';
import { resetPasswordParams, userActionConfirm } from './hooks';

interface LoginTextFieldProps extends Omit<TextFieldProps<'filled'>, 'variant'> {}

const LoginTextField = forwardRef<HTMLDivElement, LoginTextFieldProps>(
  ({ InputProps, error, ...props }: LoginTextFieldProps, ref) => (
    <TextField
      variant="filled"
      autoFocus
      label="Username"
      ref={ref}
      {...props}
      InputProps={{
        disableUnderline: true,
        ...InputProps,
        sx: {
          backgroundColor: 'background.paper',
          ...InputProps?.sx
        }
      }}
    />
  )
);

interface ResetPasswordFormType {
  identification: string;
}

interface LoginFormType {
  email: string;
  password: string;
}

interface LoginPageFormControlsProps {
  control: Control<LoginFormType, any>;
  errors: FieldErrors<LoginFormType>;
  showPassword: boolean;
  onShowPasswordClick?: (showPassword: boolean) => void;
}

const LoginPageFormControls = ({ control, errors, showPassword, onShowPasswordClick }: LoginPageFormControlsProps) => (
  <Stack spacing={1}>
    <FormControl fullWidth>
      <Controller
        name="email"
        control={control}
        rules={{ required: true }}
        render={({ field }) => <LoginTextField label="Username" {...field} error={Boolean(errors.email)} />}
      />
      {errors.email && <FormHelperText sx={{ color: 'error.main' }}>{errors.email.message}</FormHelperText>}
    </FormControl>
    <FormControl fullWidth>
      <Controller
        name="password"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <LoginTextField
            label="Password"
            {...field}
            error={Boolean(errors.password)}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => onShowPasswordClick?.(!showPassword)}
                  >
                    {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        )}
      />
      {errors.password && <FormHelperText sx={{ color: 'error.main' }}>{errors.password.message}</FormHelperText>}
    </FormControl>
  </Stack>
);

interface ResetPasswordFormProps {
  userName?: string;
  onReset?: (data: ResetPasswordFormType) => void;
  isResetingPassword?: boolean;
}

const ResetPasswordForm = ({ userName, onReset, isResetingPassword }: ResetPasswordFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ResetPasswordFormType>({
    defaultValues: {
      identification: userName
    }
  });

  return (
    <Container
      component="form"
      maxWidth="xs"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit((data) => onReset?.(data))}
      sx={{ marginTop: '20px' }}
    >
      <Stack spacing={2}>
        <Typography variant="h6">Reset password</Typography>
        <FormControl fullWidth>
          <Controller
            name="identification"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <LoginTextField label="Username" {...field} error={Boolean(errors.identification)} />
              </>
            )}
          />
        </FormControl>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          }}
        >
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            loadingPosition="start"
            loading={isResetingPassword}
            // hack: there is a warning when not using startIcon https://github.com/mui/material-ui/issues/31235
            startIcon={<React.Fragment />}
          >
            Reset password
          </LoadingButton>
        </Box>
      </Stack>
    </Container>
  );
};

export interface LoginPageProps {
  onLogin: (data: LoginFormType) => void;
  isLoggingIn?: boolean;
  resetPassword: (params: resetPasswordParams) => void;
  isResetingPassword: boolean;
  userActionConfirm?: (confirmParams: userActionConfirm) => void;
}

export const LoginPage = ({
  onLogin,
  isLoggingIn,
  resetPassword,
  isResetingPassword,
  userActionConfirm
}: LoginPageProps) => {
  const [searchParams] = useSearchParams();

  const [lastLoggedUserEmail, setLastLoggedUserEmail] = useLocalStorage<string>(
    LocalStorageKey.LastLoggedUserEmail,
    ''
  );
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginFormType>({
    defaultValues: {
      email: lastLoggedUserEmail,
      password: ''
    }
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showResetPasswordForm, setShowResetPasswordForm] = useState<boolean>(false);

  const onLoginHandler = (data: LoginFormType) => {
    setLastLoggedUserEmail(data.email);
    onLogin(data);
  };

  useEffect(() => {
    const searchParamsString = searchParams.get('userAction');
    if (searchParamsString) {
      const urlArray = searchParamsString?.split('$');
      userActionConfirm?.({
        action: 'resetPassword',
        identification: urlArray?.[1] ?? '',
        confirmCodeKey: 'resetCode',
        hash: urlArray?.[2] ?? ''
      });
    }
  }, [searchParams]);

  return (
    <Stack direction="row" spacing={2} sx={{ height: 1, overflow: 'hidden' }}>
      <Box sx={{ width: { xs: '100%', lg: '45vw' }, minWidth: { lg: 550 }, backgroundColor: 'background.default' }}>
        <Box
          component="header"
          typography="body1"
          sx={{ display: 'flex', height: APP_HEADER_HEIGHT, paddingX: 4, alignItems: 'center' }}
        >
          <BTLNetHeaderTitle variant="dark" />
        </Box>
        <FlexCenter sx={{ height: `calc(100vh - ${APP_HEADER_HEIGHT}px)` }}>
          <Container
            component="form"
            maxWidth="xs"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onLoginHandler)}
          >
            <Stack spacing={4}>
              <Typography variant="h1" typography="h5">
                Login
              </Typography>
              <LoginPageFormControls
                control={control}
                errors={errors}
                showPassword={showPassword}
                onShowPasswordClick={setShowPassword}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between'
                }}
              >
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isLoggingIn}
                  loadingPosition="start"
                  // hack: there is a warning when not using startIcon https://github.com/mui/material-ui/issues/31235
                  startIcon={<React.Fragment />}
                >
                  Login
                </LoadingButton>
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowResetPasswordForm(true);
                  }}
                >
                  Reset password
                </Link>
              </Box>
            </Stack>
          </Container>
          {/* Forgot password form */}
          {showResetPasswordForm && (
            <ResetPasswordForm
              userName={lastLoggedUserEmail}
              onReset={resetPassword}
              isResetingPassword={isResetingPassword}
            />
          )}
        </FlexCenter>
      </Box>
      <FlexBlock
        sx={{
          display: { xs: 'none', md: 'block' },
          backgroundColor: 'background.header',
          backgroundImage: 'url(/loginImage.png)',
          backgroundSize: 'cover'
        }}
      />
    </Stack>
  );
};
