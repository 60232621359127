import styled from '@emotion/styled';
import { Button, ButtonProps } from '@mui/material';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

export interface UploadButtonProps extends ButtonProps {
  accept?: string;
  multiple?: boolean;
  onUpload: (formData: FormData) => void;
}
export const UploadButton = ({ multiple = false, accept, onUpload, children, ...props }: UploadButtonProps) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files as unknown as File[];
    if (files.length > 0) {
      const formData = new FormData();
      Array.from(files).forEach((file, index) => {
        formData.append(`file${index}`, file);
      });

      onUpload?.(formData);
    }
  };

  return (
    <>
      <Button component="label" variant="contained" color="primary" tabIndex={-1} {...props}>
        {children}
        <VisuallyHiddenInput type="file" accept={accept} multiple={multiple} onChange={handleFileChange} />
      </Button>
    </>
  );
};
