import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Stack, Typography } from '@mui/material';
import { FlexCenter } from './FlexCenter';

export const NoDataFound = () => (
  <FlexCenter sx={{ maxHeight: 0.5 }}>
    <Stack spacing={1} alignItems="center">
      <ErrorOutlineOutlinedIcon />
      <Typography variant="body1" align="center">
        No data found
      </Typography>
    </Stack>
  </FlexCenter>
);
