import { Box, BoxProps } from '@mui/material';

export interface FlexCenterProps extends BoxProps {}
export const FlexCenter = ({ sx = {}, ...props }: FlexCenterProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: 1,
      justifyContent: 'center',
      width: 1,
      ...sx
    }}
    {...props}
  />
);
