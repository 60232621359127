import {
  Avatar,
  Box,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ToggleButton
} from '@mui/material';
import { FormattedDate } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { SearchSM } from 'untitledui-js-base';
import { fixedCellSize } from '../../utils';
import { ArticleTags } from '../ArticlesPage';
import { HOVER_BOX_SX, LineClamp, SortButton, TableLoadingContainer } from '../common';
import { FiltersType, SortActions } from '../hooks';
import { Article } from '../types';

export interface ArticleFiltersType {
  isMandatory?: boolean;
  search: string;
}

export interface ArticlesFiltersProps {
  filters: FiltersType<ArticleFiltersType>;
  sort: SortActions<Article>;
  disabled?: boolean;
}

export const ArticleFilters = ({
  filters: { filters, setFilters },
  sort: { toggleSort, getSortOrder },
  disabled
}: ArticlesFiltersProps) => (
  <Stack spacing={2}>
    <Stack direction="row" alignItems="center" spacing={1}>
      <TextField
        placeholder="Search in articles"
        variant="outlined"
        value={filters.search}
        size="small"
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchSM color="black" />
            </InputAdornment>
          )
        }}
        onChange={(e) => setFilters({ ...filters, search: e.target.value })}
      />
    </Stack>
    <Stack direction="row" alignItems="center" spacing={1} overflow="auto">
      <ToggleButton
        value="mandatory"
        color="secondary"
        selected={filters.isMandatory}
        onClick={() => setFilters({ ...filters, isMandatory: !filters.isMandatory })}
        disabled={disabled}
        sx={{ whiteSpace: 'nowrap' }}
      >
        Mandatory only
      </ToggleButton>
      <SortButton onClick={() => toggleSort('createdDate')} direction={getSortOrder('createdDate')} disabled={disabled}>
        Sort by Date
      </SortButton>
      <SortButton onClick={() => toggleSort('title')} direction={getSortOrder('title')} disabled={disabled}>
        Sort by Title
      </SortButton>
    </Stack>
  </Stack>
);

export interface ArticlesTableProps {
  articles: Article[];
  loading?: boolean;
  detailPath: (articleId: string) => string;
}

export const ArticlesTable = ({ articles, detailPath, loading }: ArticlesTableProps) => {
  const navigate = useNavigate();

  return (
    <TableLoadingContainer loading={loading}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={fixedCellSize(120)}>Date</TableCell>
            <TableCell sx={{ paddingLeft: 0 }}>Tags</TableCell>
            <TableCell sx={fixedCellSize(80)}>Name</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody sx={{ height: 1 }}>
          {articles.map((article) => {
            const { id: articleId, image, title, createdDate } = article;
            const articleDetailPath = detailPath(articleId);
            return (
              <TableRow key={articleId} onClick={() => navigate(articleDetailPath)} sx={HOVER_BOX_SX}>
                <TableCell sx={fixedCellSize(120)}>
                  <Box color="text.secondary" typography="body2">
                    <FormattedDate value={createdDate} month="short" day="numeric" year="numeric" />
                  </Box>
                </TableCell>
                <TableCell sx={{ paddingLeft: 0 }}>
                  <ArticleTags article={article} spacing={1} />
                </TableCell>
                <TableCell sx={fixedCellSize(80)}>
                  <Avatar variant="rounded" src={image} sx={{ width: 48, height: 48 }} alt={title}>
                    {title[0]}
                  </Avatar>
                </TableCell>
                <TableCell sx={{ width: 'auto', minWidth: '200px' }}>
                  <LineClamp maxLines={2} sx={{ fontWeight: 'bold' }}>
                    {title}
                  </LineClamp>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableLoadingContainer>
  );
};
