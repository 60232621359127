import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';

export type NotifyTypes = 'success' | 'info' | 'warning' | 'error';

export const useNotify = () => {
  const { enqueueSnackbar } = useSnackbar();
  const notify = useCallback(
    (variant: NotifyTypes, message: string) =>
      enqueueSnackbar(message, { variant, autoHideDuration: variant === 'error' ? 6000 : 3000 }),
    [enqueueSnackbar]
  );
  const notifyOnError = useCallback((message: string) => notify('error', message), [notify]);
  const notifyOnSuccess = useCallback((message: string) => notify('success', message), [notify]);

  return useMemo(() => ({ notify, notifyOnSuccess, notifyOnError }), [notify, notifyOnError, notifyOnSuccess]);
};
