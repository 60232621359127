export interface FormAnswer {
  key: string;
  label: string;
  value: string | string[];
}
export enum RequestStatus {
  Approved = 'approved',
  Pending = 'pending',
  Declined = 'declined'
}

export interface UserRequest {
  id: string;
  createdDate: Date;
  status: RequestStatus;
  userName: string;
  title: string;
  answers: FormAnswer[];
}
