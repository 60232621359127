import { Divider, IconButton, Modal, ModalProps, Stack, Typography } from '@mui/material';
import { X } from 'untitledui-js-base';
import { EditQuestionnaireConnected } from '../EditQuestionnary';
import { FormattedAnswers } from '../common';
import { UserQuestionnaire } from '../types';

export type QuestionnaireModalMode = 'showMine' | 'showOthers' | 'fill';
export interface QuestionnaireContentProps {
  mode: QuestionnaireModalMode;
  questionnaire: UserQuestionnaire;
  onClose?: () => void;
}

export const QuestionnaireContent = ({ mode, questionnaire, onClose }: QuestionnaireContentProps) => {
  if (mode === 'showMine' && questionnaire.own) return <FormattedAnswers answers={questionnaire.own.answers} />;

  if (mode === 'showOthers' && questionnaire.others.length) {
    return (
      <Stack spacing={2} divider={<Divider />}>
        {questionnaire.others.map((answer) => (
          <Stack key={answer.id} spacing={1}>
            <Typography variant="body1">{answer.user.fullName}</Typography>
            <FormattedAnswers answers={answer.answers} />
          </Stack>
        ))}
      </Stack>
    );
  }
  if (mode === 'fill' && !questionnaire.own)
    return <EditQuestionnaireConnected formId={questionnaire.id} onSubmit={onClose} />;

  return null;
};

export interface QuestionnaireModalProps extends Omit<ModalProps, 'children' | 'onClose' | 'open'> {
  handleClose: () => void;
  questionnarie?: UserQuestionnaire;
  mode?: QuestionnaireModalMode;
}

export const QuestionnaireModal = ({
  handleClose,
  questionnarie,
  mode = 'showMine',
  ...props
}: QuestionnaireModalProps) => {
  return (
    <Modal open={Boolean(questionnarie)} onClose={handleClose} disableAutoFocus {...props}>
      <Stack
        spacing={3}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          width: 800,
          maxWidth: '90vw',
          boxShadow: 24,
          paddingX: { xs: 2, md: 4 },
          paddingTop: 2,
          paddingBottom: 4
        }}
      >
        <IconButton onClick={handleClose} color="inherit" sx={{ position: 'absolute', right: '10px', top: '10px' }}>
          <X />
        </IconButton>
        <Typography variant="h5" component="h3">
          {questionnarie?.title}
        </Typography>
        {questionnarie && <QuestionnaireContent mode={mode} questionnaire={questionnarie} onClose={handleClose} />}
      </Stack>
    </Modal>
  );
};
