import {
  Box,
  Divider,
  Stack,
  StackProps,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useSuspenseQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { ReactNode, useState } from 'react';
import { FormattedDateTimeRange } from 'react-intl';
import { useParams } from 'react-router-dom';
import { CalenderDate, MarkerPin04 } from 'untitledui-js-base';
import { useCommonApi } from '../api';
import { EventActions } from './EventsPage';
import { FlexBlock, PageLayout, PageTitle, TransparentChip } from './common';
import { QuestionnaireActions, QuestionnaireModal, QuestionnaireModalMode } from './questionnaries';
import { EventStatus, UserEvent, UserEventInvitee, UserQuestionnaire } from './types';

const InviteeStatus = ({ status }: { status: EventStatus | null }) => {
  return (
    <Box>
      {status === 'attend' ? (
        <TransparentChip size="small" label="Attending" color="success" variant="filled" />
      ) : status === 'decline' ? (
        <TransparentChip size="small" label="Declined" color="error" variant="filled" />
      ) : (
        <TransparentChip size="small" label="Pending" color="warning" variant="filled" />
      )}
    </Box>
  );
};

interface InviteesTableProps {
  invitees: UserEventInvitee[];
}

const InviteesTable = ({ invitees }: InviteesTableProps) => {
  const sortedInvitess = invitees.sort((a, b) => {
    if (a.status === 'attend') return -1;
    if (b.status === 'attend') return 1;
    if (a.status === 'decline') return -2;
    if (b.status === 'decline') return 2;
    return 0;
  });
  return (
    <Box>
      <Typography variant="body1" fontWeight="bold">
        Invitees
      </Typography>
      {!isEmpty(invitees) ? (
        <TableContainer sx={{ marginLeft: -2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedInvitess.map(({ id, name, surname, status }) => (
                <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    {name} {surname}
                  </TableCell>
                  <TableCell align="right">
                    <InviteeStatus status={status} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Stack justifyContent="center" mt={3}>
          <Typography variant="body2" color="text.secondary">
            No participant has confirmed yet...
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

interface EventLayoutProps extends Omit<StackProps, 'title'> {
  title?: ReactNode;
  event: UserEvent;
  extra?: ReactNode;
  parentPath: string;
}

const EventLayout = ({ title, event, children, sx = {}, parentPath, extra, ...props }: EventLayoutProps) => (
  <PageLayout
    title={
      // we are expecting h1 elements in event content so the title should be h2 for this layout
      <PageTitle variant="h2">Events</PageTitle>
    }
    backPath={parentPath}
    withContentBlock
    {...props}
  >
    <Stack spacing={2} sx={{ height: 1, overflow: 'auto', paddingTop: { xs: 1, md: 6 }, paddingX: { xs: 1, md: 10 } }}>
      <FlexBlock>
        <Stack spacing={2} marginBottom={2}>
          <Typography variant="h1" typography="h4">
            {title}
          </Typography>
          <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
            {event?.isNew && (
              <Box maxWidth="fit-content">
                <TransparentChip size="small" label="New" color="success" variant="filled" />
              </Box>
            )}
            {event.questionnaire ? <TransparentChip label={event.questionnaire.title} /> : null}
          </Stack>
        </Stack>
        {children}
      </FlexBlock>
    </Stack>
  </PageLayout>
);

interface EventPageContentProps {
  eventId: string;
  parentPath: string;
}

export const EventPageContent = ({ eventId, parentPath }: EventPageContentProps) => {
  const { data: event, refetch, isFetching } = useSuspenseQuery(useCommonApi().getEvent(eventId));

  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<
    { questionnaire: UserQuestionnaire; mode: QuestionnaireModalMode } | undefined
  >(undefined);
  if (event === null) return <>Event not found</>;

  const { title, dateFrom, dateTo, place, content, questionnaire } = event;
  return (
    <>
      <EventLayout title={title} event={event} parentPath={parentPath}>
        <Stack direction="column" display="flex" gap={4}>
          <Box>
            <Typography variant="body1" fontWeight="bold">
              Event Details
            </Typography>
            <Stack mt={2} gap={2}>
              <Stack gap={1} direction="row">
                <SvgIcon color="primary" fontSize="small">
                  <CalenderDate />
                </SvgIcon>
                <Typography variant="body2" color="text.secondary">
                  <FormattedDateTimeRange from={dateFrom} to={dateTo} month="short" day="numeric" year="numeric" />
                </Typography>
              </Stack>
              <Stack gap={1} direction="row">
                <SvgIcon color="primary" fontSize="small">
                  <MarkerPin04 />
                </SvgIcon>
                <Typography variant="body2" color="text.secondary">
                  {place}
                </Typography>
              </Stack>
            </Stack>
          </Box>
          <EventActions event={event} onActionSuccess={() => refetch()} loading={isFetching} />
          {questionnaire && (
            <Box>
              <Typography variant="body1" fontWeight="bold">
                Questionaire
              </Typography>
              <Box mt={2}>
                <QuestionnaireActions
                  questionnaire={questionnaire}
                  onShowMyAnswers={() => setSelectedQuestionnaire({ questionnaire, mode: 'showMine' })}
                  onShowOthersAnswers={() =>
                    setSelectedQuestionnaire({
                      questionnaire,
                      mode: 'showOthers'
                    })
                  }
                  onFillQuestionaire={() => setSelectedQuestionnaire({ questionnaire, mode: 'fill' })}
                />
              </Box>
            </Box>
          )}
          <Box>
            <Typography variant="body1" fontWeight="bold">
              Event Description
            </Typography>
            <Box mt={2} component="div" dangerouslySetInnerHTML={{ __html: content }} />
          </Box>
          <Divider />
          <InviteesTable invitees={event.invitees} />
        </Stack>
      </EventLayout>
      <QuestionnaireModal
        questionnarie={selectedQuestionnaire?.questionnaire}
        mode={selectedQuestionnaire?.mode}
        handleClose={() => setSelectedQuestionnaire(undefined)}
      />
    </>
  );
};

export const EventPage = (props: Omit<EventPageContentProps, 'eventId'>) => {
  const { eventId } = useParams<{ eventId: string }>();
  if (!eventId) return null;
  return <EventPageContent eventId={eventId} {...props} />;
};
