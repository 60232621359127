import { CircularProgress, TableContainer, TableContainerProps } from '@mui/material';

import { FlexCenter } from './FlexCenter';

export interface TableLoadingContainerProps extends TableContainerProps {
  loading?: boolean;
}
export const TableLoadingContainer = ({ loading, ...props }: TableLoadingContainerProps) => {
  if (loading)
    return (
      <FlexCenter>
        <CircularProgress />
      </FlexCenter>
    );
  return <TableContainer {...props} />;
};
