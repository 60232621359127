import { PaginationOptions } from '@jgereg/paginated';
import { useCallback } from 'react';
import { usePagination } from '../../hooks';
import { useTableFilters } from '../../hooks/useTableFilters';
import { DocumentFiltersType } from '../documents/DocumentFilters';
import { DocumentMeta } from '../types';
import { FilterSortPaginated } from './useArticlesFilter';

export const useDocumentsFilter = (
  data: DocumentMeta[],
  {
    defaultPaginationState = { page: 1, pageSize: 10 }
  }: Partial<{
    defaultPaginationState: PaginationOptions;
  }> = {}
): Omit<FilterSortPaginated<DocumentMeta, any>, 'sort'> => {
  const {
    data: filteredData,
    filters,
    setFilter: setDocumentFilter
  } = useTableFilters<DocumentMeta, DocumentFiltersType>(
    data,
    [(item, { search }) => (search !== '' ? item.name.toLowerCase().includes(search.toLowerCase()) : undefined)],
    {
      defaultFilterState: {
        search: ''
      }
    }
  );

  const { items, pagination, resetPagination, setPage } = usePagination(filteredData, defaultPaginationState);

  const handleFilterChange = useCallback(
    (filters: DocumentFiltersType) => {
      resetPagination();
      setDocumentFilter(filters);
    },
    [resetPagination, setDocumentFilter]
  );

  return {
    items,
    filters: {
      filters,
      setFilters: handleFilterChange
    },
    pagination: {
      ...pagination,
      setPage
    }
  };
};
