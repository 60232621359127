export interface FormFieldOption {
  value: string;
  label: string;
}

export enum FormFieldType {
  Input = 'input',
  TextArea = 'textarea',
  Select = 'select'
}
export interface FormFieldBaseType<Value = any> {
  id: string;
  name: string;
  element: FormFieldType;
  defaultValue?: Value;
  label?: string;
  validation?: {
    required?: boolean;
  };
  placeholder?: string;
  options?: any[];
  type?: string;
}
export interface InputFormFieldType extends FormFieldBaseType {
  element: FormFieldType.Input;
  type: React.InputHTMLAttributes<unknown>['type'];
}

export interface PhoneFormFieldType extends InputFormFieldType {
  type: 'tel';
}

export interface EmailFormFieldType extends InputFormFieldType {
  type: 'email';
}
export interface RadioGroupFormFieldType extends InputFormFieldType {
  type: 'radio';
  options: FormFieldOption[];
}

export interface CheckboxGroupFormFieldType extends InputFormFieldType {
  type: 'checkbox';
  options: FormFieldOption[];
}

export interface TextAreaFormFieldType extends FormFieldBaseType<string> {
  element: FormFieldType.TextArea;
}

export interface SelectFormFieldType extends FormFieldBaseType<string> {
  element: FormFieldType.Select;
  options: FormFieldOption[];
}
