import { List, ListItem, ListProps, Stack } from '@mui/material';
import React from 'react';
import { HOVER_BOX_SX, LineClamp } from '../../app/common';
import { DocumentMeta } from '../types';
import { DocumentIcon } from './DocumentIcon';

export interface DocumentsListProps {
  documents: DocumentMeta[];
  loading?: boolean;
  onClick?: (document: DocumentMeta) => void;
  slotProps?: {
    listProps?: Partial<ListProps>;
  };
}
export const DocumentsList = ({ documents, onClick, slotProps: { listProps } = {} }: DocumentsListProps) => (
  <List sx={{ width: 1, bgcolor: 'background.paper', ...listProps?.sx }} {...listProps}>
    {documents.map((document) => (
      <React.Fragment key={document.id}>
        <ListItem
          sx={{
            paddingX: 1,
            ...HOVER_BOX_SX
          }}
          onClick={() => onClick?.(document)}
        >
          <Stack spacing={2} direction="row" alignItems="center">
            <DocumentIcon type={document.type} />
            <LineClamp maxLines={2} sx={{ fontWeight: 'bold' }}>
              {document.name}
            </LineClamp>
          </Stack>
        </ListItem>
      </React.Fragment>
    ))}
  </List>
);
