import { Button, ButtonProps, Link, LinkProps } from '@mui/material';
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'react-router-dom';

export interface RoutedButtonProps extends Omit<ButtonProps<typeof ReactRouterLink>, 'to' | 'component'> {
  to: ReactRouterLinkProps['to'];
}
export const RouterButton = (props: RoutedButtonProps) => <Button component={ReactRouterLink} {...props} />;

export interface RouterLinkProps extends Omit<LinkProps<typeof ReactRouterLink>, 'to' | 'component'> {
  to: ReactRouterLinkProps['to'];
}
export const RouterLink = (props: RouterLinkProps) => <Link component={ReactRouterLink} {...props} />;
