import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  PaperProps,
  Stack,
  useTheme
} from '@mui/material';
import { listItemButtonClasses } from '@mui/material/ListItemButton';
import { transparentize } from 'polished';
import React, { Fragment } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { XClose } from 'untitledui-js-base';
import { APP_HEADER_HEIGHT } from './AppHeader';
import { FlexBlock } from './FlexBlock';

export const SIZE = 200;

interface AppMenuItemButtonProps {
  icon: React.ReactNode;
  to: string;
  label?: string;
  badge?: React.ReactNode;
}

const AppMenuItemButton = ({ to, icon, label, badge }: AppMenuItemButtonProps) => {
  const navigate = useNavigate();
  const isActive = Boolean(useMatch(`${to}/*`));
  const { palette } = useTheme();
  const activeBgColor = transparentize(0.94, palette.background.header);
  return (
    <ListItem disablePadding>
      <ListItemButton
        disableRipple
        onClick={() => navigate(to)}
        className={isActive ? 'active' : undefined}
        sx={{
          position: 'relative',
          [`&.${listItemButtonClasses.focusVisible}, &:hover`]: {
            backgroundColor: isActive ? activeBgColor : 'grey.100',
            borderRadius: 2
          }
        }}
      >
        {isActive && (
          <Box
            sx={{
              position: 'absolute',
              inset: 0,
              backgroundColor: activeBgColor,
              borderRadius: 2
            }}
          />
        )}
        <Stack direction="row" alignItems="center">
          <ListItemIcon
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              color: isActive ? 'background.header' : 'inherit',
              height: 24,
              minWidth: 34
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={label}
            sx={{
              ml: 0,
              overflow: 'hidden',
              textWrap: 'nowrap',
              color: isActive ? 'background.header' : 'inherit'
            }}
            primaryTypographyProps={{ fontWeight: 500 }}
          />
        </Stack>
        <FlexBlock />
        {badge}
      </ListItemButton>
    </ListItem>
  );
};

export type AppMenuItem = [string, string, React.ReactNode | undefined, React.ReactNode | undefined];

export interface AppMenuSection {
  title: string;
  items: AppMenuItem[];
}

export interface AppMenuProps extends PaperProps {
  sections: AppMenuSection[];
  menuOpen: boolean;
  onClose?: () => void;
}

export const AppMenu = ({ sx = {}, sections, menuOpen = false, onClose, ...props }: AppMenuProps) => {
  const drawerContent = (
    <Box display="flex" flexDirection="column" gap={2} paddingX={1}>
      <Box mb={1} ml={1} mt={2} display={{ md: 'none' }}>
        <IconButton onClick={onClose}>
          <XClose />
        </IconButton>
      </Box>
      {sections.map(({ title, items }, idx) => (
        <Fragment key={idx}>
          <Stack spacing={1} key={title}>
            <Box color="grey" paddingLeft={3} typography="body3" sx={{ textTransform: 'uppercase' }}>
              {title}
            </Box>
            <List sx={{ p: 0 }}>
              {items.map(([name, href, icon, badge]) => (
                <AppMenuItemButton key={name} icon={icon} label={name} to={href} badge={badge} />
              ))}
            </List>
          </Stack>
          {idx !== sections.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Box>
  );

  return (
    <Paper
      {...props}
      component="nav"
      sx={{
        minWidth: {
          md: SIZE
        },
        flexShrink: { sm: 0 },
        ...sx
      }}
    >
      {/* Mobile menu */}
      <Drawer
        variant="temporary"
        open={menuOpen}
        onClose={onClose}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: SIZE
          }
        }}
      >
        {drawerContent}
      </Drawer>
      {/* Desktop menu */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: SIZE,
            top: `${APP_HEADER_HEIGHT + 20}px`,
            boxShadow: 'none',
            borderRight: 0
          }
        }}
        open
      >
        {drawerContent}
      </Drawer>
    </Paper>
  );
};
