import axios from 'axios';
import { LocalStorageKey } from '../constants';
import { env } from '../env';

export const api = axios.create({
  baseURL: env.API_URL,
  headers: {
    'Content-type': 'application/json',
    'Accept-Language': 'en_GB'
  }
});

api.interceptors.request.use(
  async (config) => {
    const sessionKey = await getSessionId();
    config.headers.Authorization = `Token ${sessionKey}`;
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem(LocalStorageKey.SessionToken);
      window.location.href = '/';
    } else {
      console.error('An error occurred:', error.message);
    }
    return Promise.reject(error);
  }
);

//TODO: implement refresh token when expired
export const getSessionId = async () => {
  const storedAccessKey = localStorage.getItem(LocalStorageKey.SessionToken);
  if (storedAccessKey) return storedAccessKey;

  const result = await fetch(`${env.API_URL}/auth/getAccessKey`);
  const data = await result.json();
  const sesstionKey = data.results.accessKey;
  localStorage.setItem(LocalStorageKey.SessionToken, sesstionKey);
  return sesstionKey;
};
