import { PaginationOptions } from '@jgereg/paginated';
import { first, orderBy } from 'lodash';
import { useCallback, useMemo } from 'react';

import { usePagination } from '../../hooks/usePagination';
import { SortOption, SortType, useSortingState } from '../../hooks/useSortingState';
import { useTableFilters } from '../../hooks/useTableFilters';
import { RequestFiltersType } from '../requests';
import { RequestStatus, UserRequest } from '../types';
import { FilterSortPaginated } from './useArticlesFilter';

export const useRequestsFilter = (
  data: UserRequest[],
  {
    defaultSortState = { field: 'createdDate', order: SortType.Desc },
    defaultPaginationState = { page: 1, pageSize: 10 }
  }: Partial<{
    defaultSortState: SortOption<UserRequest>;
    defaultPaginationState: PaginationOptions;
  }> = {}
): FilterSortPaginated<UserRequest, RequestFiltersType> => {
  const {
    data: filteredData,
    filters,
    setFilter: setRequestFilter
  } = useTableFilters<UserRequest, RequestFiltersType>(
    data,
    [
      ({ status }, { approved, declined, pending }) => {
        const statuses = [];
        if (approved) statuses.push(RequestStatus.Approved);
        if (declined) statuses.push(RequestStatus.Declined);
        if (pending) statuses.push(RequestStatus.Pending);
        return statuses.length > 0 ? statuses.includes(status) : undefined;
      }
    ],
    {
      defaultFilterState: {}
    }
  );

  const { sortState, toggle, getSortOrder } = useSortingState<UserRequest>([defaultSortState]);

  const sortedData = useMemo(() => {
    let result: UserRequest[] = filteredData;
    const sort = first(sortState);
    if (sort) {
      result = orderBy(result, sort.field, sort.order === SortType.Asc ? 'asc' : 'desc');
    }
    return result;
  }, [data, sortState, filteredData]);

  const { items, pagination, resetPagination, setPage } = usePagination(sortedData, defaultPaginationState);

  const handleSortChange = useCallback(
    (field: keyof UserRequest) => {
      resetPagination();
      toggle(field);
    },
    [resetPagination, toggle]
  );

  const handleFilterChange = useCallback(
    (filters: RequestFiltersType) => {
      resetPagination();
      setRequestFilter(filters);
    },
    [resetPagination, setRequestFilter]
  );

  return {
    items,
    pagination: {
      ...pagination,
      setPage
    },
    sort: {
      getSortOrder,
      toggleSort: handleSortChange
    },
    filters: {
      filters,
      setFilters: handleFilterChange
    }
  };
};
