import { Box, BoxProps } from '@mui/material';
import { forwardRef } from 'react';

const flexStretch = {
  minWidth: 0,
  flexShrink: 1,
  flexGrow: 1,
  flexBasis: 0
};

export interface FlexBlockProps extends BoxProps {
  scroll?: boolean;
  cover?: boolean;
}

export const FlexBlock = forwardRef(({ sx, scroll, cover, ...props }: FlexBlockProps, ref) => (
  <Box
    {...props}
    sx={{
      ...flexStretch,
      //overflow: scroll ? 'auto' : undefined,
      ...(cover ? { display: 'flex', '& > *': flexStretch } : {}),
      ...sx
    }}
    ref={ref}
  />
));
FlexBlock.displayName = 'FlexBlock';
