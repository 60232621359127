import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { format, isAfter, subDays } from 'date-fns';
import { isArray, isEmpty, isNil } from 'lodash';
import { PasswordChangeFormType } from '../app/PasswordChangePage';
import { FormFieldBaseType } from '../app/dynamicForm';
import { resetPasswordAction, userActionConfirm } from '../app/hooks';
import {
  Article,
  DocumentMeta,
  EventStatus,
  FileNode,
  RequestStatus,
  Role,
  User,
  UserEvent,
  UserQuestionnaire,
  UserQuestionnaireAnswer,
  UserRequest
} from '../app/types';
import { useNotify } from '../hooks/useNotify';
import { qsUrl } from '../utils';
import { api } from './api';
import { STALE_TIME } from './constants';
import { mutation, query } from './reactQuery';
import { ApiResponse } from './types';
import {
  onSuccessCustomApiResponse,
  parseDate,
  parseDocumentType,
  parseFormData,
  parseId,
  parseUserName
} from './utils';

const ROOT_KEY = ['common'];

const ENDPOINTS = {
  LOGIN: '/users/loginUser',
  PASSWORD_RESET: '/users/resetPassword',
  PASSWORD_CHANGE: '/users/changePassword',
  USER_ACTION: (action: resetPasswordAction) => `/users/${action}`,
  CHECK_LOGIN: '/users/checkLogin',
  ARTICLES: '/_btlNet/posts',
  GET_ARTICLE: (articleId: string) => `/_btlNet/posts/${articleId}`,
  ARTICLE_MARK_AS_READ: '/_btlNet/articleViewed',
  GET_FORMS: '/_btlNet/forms',
  GET_FORM: (formId: string) => `/_btlNet/forms/${formId}`,
  SUBMIT_FORM: (formId: string) => `/_btlNet/forms/${formId}`,
  GET_REQUESTS: '/_btlNet/applications',
  SET_REQUEST_STATUS: '/_btlNet/applicationStatus',
  CANCAL_REQUEST: '/_btlNet/applicationCancel',
  GET_DOCUMENTS: (folderId?: string) => `/_btlNet/documents${folderId ? `/${folderId}` : ''}`,
  UPLOAD_DOCUMENTS: '/_btlNet/uploadFile',
  GET_RECENT_DOCUMENTS: '/_btlNet/documentsWidget',
  EDIT_DOCUMENT: (documentId: string) => `/_btlNet/file/${documentId}`,
  GET_EVENTS: (eventId?: string) => `/_btlNet/events${eventId ? `/${eventId}` : ''}`,
  SET_EVENT_STATUS: '/_btlNet/eventStatus',
  GET_QUESTIONNARIES: (questinaireId?: string) => `/_btlNet/questionnaires${questinaireId ? `/${questinaireId}` : ''}`,
  GET_NOTIFICATIONS: '/_btlNet/notifications',
  SET_NOTIFICATION_STATUS: (id: number) => `/_btlNet/notifications/${id}`
};

interface UserResponse {
  id: number;
  email: string;
  name: string;
  phone: string;
  surname: string;
  btlNet: {
    role: {
      id: number;
      name: string;
      type: Role;
    };
  };
}

interface ArticleResponse {
  id: string;
  createdDate: string;
  title: string;
  description: string;
  btlNet: {
    isMandatory: boolean;
    isViewedByUser: boolean;
  };
  images: {
    head?: {
      url: string;
    };
  };
}

export enum FormType {
  Request = 'request',
  Questionnaire = 'questionnaire'
}

export interface Form {
  id: string;
  title: string;
  content: FormFieldBaseType[];
}

interface FormResponse {
  id: number;
  title: string;
  content: FormFieldBaseType[];
}

interface ApplicationResponse {
  id: number;
  title: string;
  createdDate: string;
  content: {
    name: string;
    label: string;
    value: string | string[];
  }[];
  form: {
    id: number;
    title: string;
  };
  status: {
    label: string;
    name: RequestStatus;
  };
  user: UserResponse;
}

interface QuestionnaireAnswerResponse {
  id: number;
  createdDate: string;
  content: {
    name: string;
    label: string;
    value: string | string[];
  }[];
  user: UserResponse;
}

interface QuestionnaireResponse {
  id: number; //formId
  title: string;
  createdDate: string;
  form: {
    id: string;
    title: string;
  };
  event?: {
    id: number;
    title: string;
  };
  own?: QuestionnaireAnswerResponse;
  others: QuestionnaireAnswerResponse[];
}

interface DocumentResponse {
  id: number;
  name: string;
  fileUrl: string;
  type: string;
  thumbnailUrl?: string;
}

interface FileNodeResponse {
  folderId: number;
  parentId?: number;
  folderName?: string;
  data?: {
    folders?: {
      id: number;
      name: string;
    }[];
    files?: DocumentResponse[];
  };
}

interface RecentDocumentsResponse {
  totalFiles: number;
  files: DocumentResponse[];
}

interface EventResponse {
  id: number;
  dateFrom: string;
  dateTo: string;
  place: string;
  title: string;
  description: string;
  userStatus: EventStatus | null;
  questionnaire?: QuestionnaireResponse;
  assignedUsers: { status: EventStatus | null; user: { id: number; name: string; surname: string } }[];
}

export enum NotificationModel {
  Article = 'Article',
  Document = 'Document',
  Request = 'Request',
  Event = 'Event',
  Questionnaire = 'Questionnaire'
}

export interface Notification {
  id: number;
  objectId: number;
  objectModel: NotificationModel;
  type: string;
  viewed: boolean;
  createdDate: string;
  extraData?: {
    title: string;
    isMandatory?: boolean;
    url: string;
  };
}

const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');

//#region parsers
const parseUserResponse = (data: UserResponse): User => ({
  id: parseId(data.id),
  email: data.email,
  name: data.name,
  fullName: parseUserName({ firstname: data.name, lastname: data.surname }),
  role: data.btlNet.role.type
});

const parseApplicationResponse = (data: ApplicationResponse): UserRequest => ({
  id: parseId(data.id),
  createdDate: parseDate(data.createdDate),
  status: data.status.name,
  userName: parseUserName({ firstname: data.user.name, lastname: data.user.surname }),
  title: data.form.title,
  answers: data.content.map(({ name, label, value }) => ({
    key: name,
    label,
    value
  }))
});

const parseQuestionnaireAnswer = ({
  id,
  createdDate,
  content,
  user
}: QuestionnaireAnswerResponse): UserQuestionnaireAnswer => ({
  id: parseId(id),
  createdDate: parseDate(createdDate),
  answers: isEmpty(content)
    ? []
    : content.map(({ name, label, value }) => ({
        key: name,
        label,
        value
      })),
  user: parseUserResponse(user)
});

const parseQuestionnaireResponse = ({
  id,
  title,
  createdDate,
  event,
  own,
  others
}: QuestionnaireResponse): UserQuestionnaire => ({
  id: parseId(id),
  title: title,
  createdDate: parseDate(createdDate),
  event: event
    ? {
        id: parseId(event.id),
        title: event.title
      }
    : undefined,
  own: own ? parseQuestionnaireAnswer(own) : undefined,
  others: others.map(parseQuestionnaireAnswer).filter(({ answers }) => !isEmpty(answers))
});

const parseArticle = ({
  id,
  createdDate: createdDateFromProps,
  title,
  images,
  description,
  btlNet
}: ArticleResponse): Article => {
  const today = new Date();
  const createdDate = parseDate(createdDateFromProps);
  const sevenDaysFromToday = subDays(today, 7);
  const createdWithinLast7Days = isAfter(createdDate, sevenDaysFromToday);

  return {
    id: parseId(id),
    createdDate,
    title,
    image: images.head?.url,
    htmlContent: description,
    isNew: createdWithinLast7Days,
    isMandatory: btlNet.isMandatory && !btlNet.isViewedByUser
  };
};

const parseDocument = ({ id, name, fileUrl, type }: DocumentResponse): DocumentMeta => ({
  id: parseId(id),
  name,
  url: fileUrl,
  type: parseDocumentType(type)
});

const parseFileNode = (node: FileNodeResponse): FileNode => {
  const parsedFolderId = isNil(node.parentId) ? undefined : parseId(node.parentId);
  return {
    folderId: parseId(node.folderId),
    folderName: node.folderName,
    parentId: parsedFolderId === '0' ? 'root' : parsedFolderId,
    folders:
      node.data?.folders?.map(({ id, name }) => ({
        id: parseId(id),
        name
      })) ?? [],
    documents: node.data?.files?.map(parseDocument) ?? []
  };
};

const parseEvent = (event: EventResponse): UserEvent => ({
  id: parseId(event.id),
  title: event.title,
  dateFrom: parseDate(event.dateFrom),
  dateTo: parseDate(event.dateTo),
  place: event.place,
  isNew: false,
  content: event.description,
  status: event.userStatus,
  invitees: event.assignedUsers.map(({ status, user }) => ({ ...user, status })),
  questionnaire: event.questionnaire ? parseQuestionnaireResponse(event.questionnaire) : undefined
});

const parseForm = ({ id, ...rest }: FormResponse): Form => ({ id: parseId(id), ...rest });
//#endregion

//#region queries
const getArticles = query(
  async ({ limit }: Partial<{ limit: number }> = {}) =>
    await api
      .get<ApiResponse<ArticleResponse[]>>(qsUrl(ENDPOINTS.ARTICLES, { visibility: true, limit }))
      .then((response) => response.data.results.map(parseArticle)),
  [...ROOT_KEY, 'articles'],
  { staleTime: STALE_TIME.STABLE }
);
const getArticle = query(
  async (articleId: string) => {
    const article = (await api.get<ApiResponse<ArticleResponse | []>>(ENDPOINTS.GET_ARTICLE(articleId))).data.results;
    return isArray(article) ? null : parseArticle(article);
  },
  [...ROOT_KEY, 'articles', 'article'],
  { staleTime: STALE_TIME.STABLE }
);

const getForm = query(
  async (formId: string, formType: FormType) => {
    const form = (await api.get<ApiResponse<FormResponse>>(qsUrl(ENDPOINTS.GET_FORM(formId), { type: formType }))).data
      .results;
    return form ? parseForm(form) : null;
  },
  [...ROOT_KEY, 'forms', 'form'],
  { staleTime: STALE_TIME.STABLE }
);

const getForms = query(
  async (formType: FormType) =>
    (await api.get<ApiResponse<FormResponse[]>>(qsUrl(ENDPOINTS.GET_FORMS, { type: formType }))).data.results.map(
      parseForm
    ),
  [...ROOT_KEY, 'forms'],
  { staleTime: STALE_TIME.STABLE }
);

const getRequests = query(
  async () =>
    (await api.get<ApiResponse<ApplicationResponse[]>>(ENDPOINTS.GET_REQUESTS)).data.results.map(
      parseApplicationResponse
    ),
  [...ROOT_KEY, 'requests'],
  { staleTime: STALE_TIME.STABLE }
);

const getQuestionnaries = query(
  async () =>
    (await api.get<ApiResponse<QuestionnaireResponse[]>>(ENDPOINTS.GET_QUESTIONNARIES())).data.results.map(
      parseQuestionnaireResponse
    ),
  [...ROOT_KEY, 'questionnaries', 'list'],
  { staleTime: STALE_TIME.STABLE }
);

const getQuestionnaire = query(
  async (questionaryId: string) =>
    parseQuestionnaireResponse(
      (await api.get<ApiResponse<QuestionnaireResponse>>(ENDPOINTS.GET_QUESTIONNARIES(questionaryId))).data.results
    ),
  [...ROOT_KEY, 'questionnaries'],
  { staleTime: STALE_TIME.STABLE }
);

const getDocuments = query(
  async (folderId?: string) =>
    parseFileNode((await api.get<ApiResponse<FileNodeResponse>>(ENDPOINTS.GET_DOCUMENTS(folderId))).data.results),
  [...ROOT_KEY, 'documents'],
  { staleTime: STALE_TIME.STABLE }
);

const getEvents = query(
  async ({ dateFrom, dateTo, limit }: Partial<{ dateFrom: Date; dateTo: Date; limit: number }> = {}) =>
    (
      await api.get<ApiResponse<EventResponse[]>>(
        qsUrl(ENDPOINTS.GET_EVENTS(), {
          visibility: true,
          dateFrom: dateFrom && formatDate(dateFrom),
          dateTo: dateTo && formatDate(dateTo),
          limit
        })
      )
    ).data.results.map(parseEvent),
  [...ROOT_KEY, 'events', 'list'],
  { staleTime: STALE_TIME.STABLE }
);

const getEvent = query(
  async (eventId: string) => {
    const event = (await api.get<ApiResponse<EventResponse | []>>(ENDPOINTS.GET_EVENTS(eventId))).data.results;
    return isArray(event) ? null : parseEvent(event);
  },
  [...ROOT_KEY, 'events', 'detail'],
  { staleTime: STALE_TIME.STABLE }
);

const getUser = query(
  async () => {
    const userData = (await api.get<ApiResponse<{ user: UserResponse }>>(ENDPOINTS.CHECK_LOGIN)).data.results.user;
    return userData ? parseUserResponse(userData) : null;
  },
  [...ROOT_KEY, 'user'],
  { staleTime: STALE_TIME.SHORT }
);

const getRecentDocuments = query(
  async () => {
    const { totalFiles, files } = (await api.get<ApiResponse<RecentDocumentsResponse>>(ENDPOINTS.GET_RECENT_DOCUMENTS))
      .data.results;
    return {
      totalCount: totalFiles,
      documents: files.map(parseDocument)
    };
  },
  [...ROOT_KEY, 'documents', 'recent'],
  { staleTime: STALE_TIME.STABLE }
);

const getNotifications = query(
  async () => (await api.get<ApiResponse<Notification[]>>(ENDPOINTS.GET_NOTIFICATIONS)).data.results,
  [...ROOT_KEY, 'notifications'],
  { staleTime: STALE_TIME.STABLE }
);
//#endregion

export const useCommonApi = () => {
  const { notify } = useNotify();
  const queryClient = useQueryClient();

  const update = <T>(queryKey: QueryKey, updater: (data: T) => T) => {
    const data: T | undefined = queryClient.getQueryData(queryKey);
    const updated = data && updater(data);
    if (data) queryClient.setQueryData(queryKey, updated);
    return updated;
  };

  return {
    login: () =>
      mutation(
        async ({ email, password }: { email: string; password: string }) =>
          (await api.post<ApiResponse>(ENDPOINTS.LOGIN, { email, password })).data,
        {
          onSuccess: onSuccessCustomApiResponse({
            failed: (_, __, data) => {
              notify('error', data.message || 'Login failed');
              console.error(data.message);
            }
          })
        }
      ),
    resetPassword: () =>
      mutation(
        async ({
          identification
        }: {
          identification: string;
        }) => (await api.post<ApiResponse>(ENDPOINTS.PASSWORD_RESET, { identification })).data,
        {
          onSuccess: onSuccessCustomApiResponse({
            executed: () => {
              notify('success', 'Password link was sent to your e-mail box');
            },
            failed: (_, __, data) => {
              notify('error', data.message || 'Reset password failed');
              console.error(data.message);
            }
          })
        }
      ),
    changePassword: () =>
      mutation(
        async ({ currentPassword, newPassword, newPasswordAgain }: PasswordChangeFormType) =>
          (
            await api.post<ApiResponse>(ENDPOINTS.PASSWORD_CHANGE, {
              currentPassword,
              newPassword,
              newPasswordAgain
            })
          ).data,
        {
          onSuccess: onSuccessCustomApiResponse({
            executed: () => {
              notify('success', 'Password was changed successfully');
            },
            failed: (_, __, data) => {
              notify('error', data.message || 'Change password failed');
              console.error(data.message);
            }
          })
        }
      ),
    userAction: () =>
      mutation(
        async ({ action, identification, confirmCodeKey, hash }: userActionConfirm) =>
          (
            await api.post<ApiResponse>(ENDPOINTS.USER_ACTION(action), {
              identification,
              [confirmCodeKey]: hash
            })
          ).data,
        {
          onSuccess: onSuccessCustomApiResponse({
            executed: () => {
              notify('success', 'Password was reset successfully. We send you an email with new password');
            },
            failed: (_, __, data) => {
              notify('error', data.message || 'Reset password failed');
              console.error(data.message);
            }
          })
        }
      ),
    markArticleAsRead: () =>
      mutation(
        async (articleId: string) => (await api.post<ApiResponse>(ENDPOINTS.ARTICLE_MARK_AS_READ, { articleId })).data,
        {
          onSuccess: onSuccessCustomApiResponse({
            executed: () => {
              notify('success', 'Article marked as read');
              queryClient.invalidateQueries({ queryKey: getArticles().queryKey });
            },
            failed: (_, __, data) => {
              notify('error', 'Article mark as read failed');
              console.error(data.message);
            }
          })
        }
      ),
    setRequestStatus: () =>
      mutation(
        async ({ requestId, status }: { requestId: string; status: RequestStatus }) =>
          (
            await api.post<ApiResponse>(ENDPOINTS.SET_REQUEST_STATUS, {
              applicationId: requestId,
              status
            })
          ).data,
        {
          onSuccess: onSuccessCustomApiResponse({
            executed: (variables) => {
              //optimistic update
              const requestsQueryKey = getRequests().queryKey;
              update<UserRequest[]>(requestsQueryKey, (requests) =>
                requests.map((r) =>
                  r.id === variables.requestId
                    ? {
                        ...r,
                        status: variables.status
                      }
                    : r
                )
              );
              queryClient.invalidateQueries({ queryKey: requestsQueryKey });
            }
          })
        }
      ),
    cancelRequest: () =>
      mutation(
        async ({ requestId }: { requestId: string }) =>
          (await api.post<ApiResponse>(ENDPOINTS.CANCAL_REQUEST, { applicationId: requestId })).data,
        {
          onSuccess: onSuccessCustomApiResponse({
            executed: () => {
              queryClient.invalidateQueries({ queryKey: getRequests().queryKey });
            }
          })
        }
      ),
    submitForm: () =>
      mutation(
        async ({ formId, formType, data }: { formId: string; formType: FormType; data: Record<string, any> }) =>
          (await api.post<ApiResponse>(qsUrl(ENDPOINTS.SUBMIT_FORM(formId), { type: formType }), { formData: data }))
            .data,
        {
          onSuccess: onSuccessCustomApiResponse({
            executed: () => {
              notify('success', 'Form submitted succesfully');
              queryClient.invalidateQueries({ queryKey: getRequests().queryKey });
              queryClient.invalidateQueries({ queryKey: getQuestionnaries().queryKey });
            },
            failed: (_, __, data) => {
              notify('error', 'Form submission failed');
              console.error(data.message);
            }
          }),
          onError: (error) => {
            notify('error', 'Form submission failed');
            console.error(error);
          }
        }
      ),
    uploadDocuments: () =>
      mutation(
        async ({ formData, folderId }: { formData: FormData; folderId?: string }) => {
          const files = await parseFormData(formData);
          return (await api.post<ApiResponse>(ENDPOINTS.UPLOAD_DOCUMENTS, { files, folderId })).data;
        },
        {
          onSuccess: onSuccessCustomApiResponse({
            executed: () => {
              notify('success', 'Documents uploaded succesfully');
              queryClient.invalidateQueries({ queryKey: getDocuments().queryKey });
            },
            failed: (_, __, data) => {
              notify('error', 'Document upload failed');
              console.error(data.message);
            }
          }),
          onError: (error) => {
            console.error(error);
          }
        }
      ),
    editDocument: () =>
      mutation(
        async ({ documentId, name }: { documentId: string; name: string }) =>
          (await api.post<ApiResponse>(ENDPOINTS.EDIT_DOCUMENT(documentId), { name })).data,
        {
          onSuccess: onSuccessCustomApiResponse({
            executed: () => {
              notify('success', 'Document edited succesfully');
              queryClient.invalidateQueries({ queryKey: getDocuments().queryKey });
            },
            failed: (_, __, data) => {
              notify('error', 'Failed to edit document');
              console.error(data.message);
            }
          })
        }
      ),
    removeDocument: () =>
      mutation(
        async ({ documentId }: { documentId: string }) =>
          (await api.delete<ApiResponse>(ENDPOINTS.EDIT_DOCUMENT(documentId))).data,
        {
          onSuccess: onSuccessCustomApiResponse({
            executed: () => {
              notify('success', 'Document removed succesfully');
              queryClient.invalidateQueries({ queryKey: getDocuments().queryKey });
            },
            failed: (_, __, data) => {
              notify('error', 'Document removal failed');
              console.error(data.message);
            }
          })
        }
      ),
    setEventStatus: () =>
      mutation(
        async ({ eventId, status }: { eventId: string; status: EventStatus | null }) =>
          (
            await api.post<ApiResponse>(ENDPOINTS.SET_EVENT_STATUS, {
              eventId,
              status
            })
          ).data,
        {
          onSuccess: onSuccessCustomApiResponse({
            executed: (variables) => {
              //optimistic update
              const eventsQueryKey = getEvents().queryKey;
              const eventDetailQueryKey = getEvent(variables.eventId).queryKey;
              update<UserEvent[]>(eventsQueryKey, (events) =>
                events.map((e) => (e.id === variables.eventId ? { ...e, status: variables.status } : e))
              );
              update<UserEvent>(eventDetailQueryKey, (event) => ({
                ...event,
                status: variables.status
              }));
              queryClient.invalidateQueries({ queryKey: eventsQueryKey });
              queryClient.invalidateQueries({ queryKey: eventDetailQueryKey });
            }
          })
        }
      ),
    setNotificationStatus: () =>
      mutation(
        async ({
          id
        }: {
          id: number;
        }) => (await api.put<ApiResponse>(ENDPOINTS.SET_NOTIFICATION_STATUS(id))).data,
        {
          onSuccess: onSuccessCustomApiResponse({
            executed: () => {
              queryClient.invalidateQueries({ queryKey: getNotifications().queryKey });
            }
          })
        }
      ),
    getUser,
    getArticle,
    getArticles,
    getForm,
    getForms,
    getRequests,
    getDocuments,
    getRecentDocuments,
    getEvents,
    getEvent,
    getQuestionnaries,
    getQuestionnaire,
    getNotifications
  };
};
