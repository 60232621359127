import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {
  Box,
  BoxProps,
  Divider,
  GridProps,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { isString } from 'lodash';
import React from 'react';
import { DotsVertical, Download01, Edit01, Folder as FolderIcon, Trash01 } from 'untitledui-js-base';
import { DocumentsViewMode } from '../../constants';
import { FlexBlock, FlexCenter, HoverBox, ScrollableContentBlock } from '../common';
import { DocumentMeta, FolderMeta } from '../types';
import { DocumentIcon } from './DocumentIcon';

const GRID_ITEM_PROPS: Partial<GridProps> = {
  xs: 6,
  md: 4,
  lg: 3
};

interface FileMenuProps {
  onDownload?: () => void;
  onEdit?: () => void;
  onRemove?: () => void;
}

const FileMenu = ({ onDownload, onEdit, onRemove }: FileMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleFileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const onDownloadClick = (event: React.MouseEvent<HTMLElement>) => {
    onDownload?.();
    handleClose(event);
  };

  const onEditClick = (event: React.MouseEvent<HTMLElement>) => {
    onEdit?.();
    handleClose(event);
  };

  const onRemoveClick = (event: React.MouseEvent<HTMLElement>) => {
    onRemove?.();
    handleClose(event);
  };

  return (
    <div>
      <IconButton aria-haspopup="true" onClick={handleFileMenuOpen} size="small">
        <DotsVertical />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {onDownload && (
          <MenuItem onClick={onDownloadClick} dense>
            <ListItemIcon>
              <Download01 size="1.3rem" />
            </ListItemIcon>
            Download
          </MenuItem>
        )}
        {onEdit && (
          <MenuItem onClick={onEditClick} dense>
            <ListItemIcon>
              <Edit01 size="1.3rem" />
            </ListItemIcon>
            Rename
          </MenuItem>
        )}
        {onRemove && [
          <Divider key="divider" />,
          <MenuItem onClick={onRemoveClick} key="removeMenu" dense>
            <ListItemIcon>
              <Trash01 size="1.3rem" />
            </ListItemIcon>
            Remove
          </MenuItem>
        ]}
      </Menu>
    </div>
  );
};

interface FileNodeListItemProps extends BoxProps {
  icon?: React.ReactNode;
  name: string | React.ReactNode;
  onDownload?: () => void;
  onEdit?: () => void;
  onRemove?: () => void;
  extra?: React.ReactNode;
}

const FileNodeListItem = ({ icon, name, extra, ...props }: FileNodeListItemProps) => (
  <HoverBox {...props} padding={2}>
    <Stack direction="row" minHeight="34px" alignItems="center" height={1}>
      {icon && (
        <Box display="flex" height={1} alignItems="center">
          {icon}
        </Box>
      )}
      <Box paddingLeft={2} sx={{ overflow: 'auto', wordWrap: 'break-word' }}>
        {isString(name) ? (
          <Typography fontWeight="medium" variant="body1">
            {name}
          </Typography>
        ) : (
          name
        )}
      </Box>
      <FlexBlock />
      {extra}
    </Stack>
  </HoverBox>
);

interface FolderProps extends BoxProps {
  folder: FolderMeta;
}

const Folder = ({ folder: { name }, ...props }: FolderProps) => (
  <FileNodeListItem icon={<FolderIcon height="1.5rem" />} name={name} {...props} />
);

interface DocumentMenuProps {
  onDownload?: (document: DocumentMeta) => void;
  onEdit?: (document: DocumentMeta) => void;
  onRemove?: (document: DocumentMeta) => void;
}

interface DocumentProps extends BoxProps, DocumentMenuProps {
  document: DocumentMeta;
}

const Document = ({ document, onDownload, onEdit, onRemove, ...props }: DocumentProps) => (
  <FileNodeListItem
    {...props}
    icon={<DocumentIcon type={document.type} />}
    name={document.name}
    extra={
      <FileMenu
        onDownload={onDownload ? () => onDownload(document) : undefined}
        onEdit={onEdit ? () => onEdit(document) : undefined}
        onRemove={onRemove ? () => onRemove(document) : undefined}
      />
    }
  />
);

export interface FileNodeListProps {
  viewMode?: DocumentsViewMode;
  folders: FolderMeta[];
  documents: DocumentMeta[];
  onFolderSelect?: (folder: FolderMeta) => void;
  onDocumentSelect?: (document: DocumentMeta) => void;
  documentMenuProps?: DocumentMenuProps;
}

export interface FileNodeListViewProps extends Omit<FileNodeListProps, 'viewMode'> {}

export const FileNodeListView = ({
  folders,
  documents,
  onDocumentSelect,
  onFolderSelect,
  documentMenuProps
}: FileNodeListViewProps) => (
  <Stack spacing={2}>
    {folders.map((folder) => (
      <Folder key={folder.id} folder={folder} onClick={() => onFolderSelect?.(folder)} />
    ))}
    {documents.map((document) => (
      <Document
        key={document.id}
        document={document}
        onClick={() => onDocumentSelect?.(document)}
        {...documentMenuProps}
      />
    ))}
  </Stack>
);

export interface FileNodeGridViewProps extends Omit<FileNodeListProps, 'viewMode'> {}

export const FileNodeGridView = ({
  folders,
  documents,
  onDocumentSelect,
  onFolderSelect,
  documentMenuProps
}: FileNodeGridViewProps) => (
  <Stack spacing={4}>
    {folders.length > 0 && (
      <Stack spacing={2}>
        <Typography variant="body1">Folders</Typography>
        <Grid container sx={{ width: 1 }} spacing={2}>
          {folders.map((folder) => (
            <Grid {...GRID_ITEM_PROPS} key={`folder-${folder.id}`}>
              <Folder folder={folder} onClick={() => onFolderSelect?.(folder)} height={1} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    )}
    {documents.length > 0 && (
      <Stack spacing={2}>
        <Typography variant="body1">Documents</Typography>
        <Grid container sx={{ width: 1 }} spacing={2}>
          {documents.map((document) => (
            <Grid {...GRID_ITEM_PROPS} key={`document-${document.id}`}>
              <Document document={document} onClick={() => onDocumentSelect?.(document)} {...documentMenuProps} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    )}
  </Stack>
);

export const FileNodeList = ({ viewMode, folders, documents, ...props }: FileNodeListProps) => {
  const noDataFound = folders.length === 0 && documents.length === 0;

  if (noDataFound)
    return (
      <FlexCenter sx={{ maxHeight: 0.5 }}>
        <Stack spacing={1} alignItems="center">
          <ErrorOutlineOutlinedIcon />
          <Typography variant="body1" align="center">
            This folder is empty
          </Typography>
        </Stack>
      </FlexCenter>
    );

  return (
    <ScrollableContentBlock sx={{ backgroundColor: 'inherit', padding: 0 }}>
      {viewMode === 'grid' ? (
        <FileNodeGridView folders={folders} documents={documents} {...props} />
      ) : (
        <FileNodeListView folders={folders} documents={documents} {...props} />
      )}
    </ScrollableContentBlock>
  );
};
