import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers';
import { addDays, eachDayOfInterval, format, startOfWeek } from 'date-fns';
import React from 'react';
import { TransparentChip } from '../common';
import { UserEvent } from '../types';

interface WeekPickerProps {
  date: Date;
  onChange: (date: Date) => void;
  events?: UserEvent[] | null;
}

const WeekPicker: React.FC<WeekPickerProps> = ({ date, onChange, events }) => {
  const startOfCurrentWeek = startOfWeek(date);
  const days = Array.from({ length: 7 }, (_, i) => addDays(startOfCurrentWeek, i));

  const eventsByDate = events?.reduce<Record<string, UserEvent[]>>((acc, event) => {
    const start = new Date(event.dateFrom);
    const end = new Date(event.dateTo);

    eachDayOfInterval({ start, end }).forEach((date) => {
      const eventDate = format(date, 'yyyy-MM-dd');
      if (!acc[eventDate]) {
        acc[eventDate] = [];
      }
      acc[eventDate].push(event);
    });

    return acc;
  }, {});

  return (
    <Grid container spacing={0} columns={7}>
      {days.map((day, idx) => {
        const formattedDay = format(day, 'yyyy-MM-dd');
        const dayEvents = eventsByDate?.[formattedDay] || [];
        return (
          <Grid item key={day.toISOString()} position="relative" xs={1}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ position: 'absolute', top: 16, left: 16, right: 0, zIndex: 5 }}
            >
              {format(day, 'EEE')}
            </Typography>
            <PickersDay
              day={day}
              onDaySelect={() => onChange(day)}
              outsideCurrentMonth={false}
              isFirstVisibleCell={false}
              isLastVisibleCell={false}
              today={false}
              disableMargin
              sx={{
                border: '1px solid',
                borderRight: idx === 6 ? '1px solid' : '0',
                borderColor: 'divider',
                borderRadius: '0',
                justifyContent: 'flex-start',
                width: '100%',
                px: 2,
                py: 7,
                fontWeight: 600
              }}
            />
            {dayEvents.length > 0 && (
              <Tooltip
                title={
                  <Box sx={{ whiteSpace: 'pre-line' }}>
                    {dayEvents.map((event, idx) => (
                      <Box component="span" display="block" key={idx}>
                        {event.title}
                      </Box>
                    ))}
                  </Box>
                }
                arrow
                disableHoverListener={dayEvents.length === 0}
                placement="top-start"
              >
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 10,
                    left: 16
                  }}
                >
                  <TransparentChip size="small" label={`${dayEvents.length}`} color="info" variant="filled" />
                </Box>
              </Tooltip>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

interface WeekCalendarProps {
  events?: UserEvent[] | null;
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
}

export const WeekCalendar = ({ selectedDate, setSelectedDate, events }: WeekCalendarProps) => (
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1rem' }}>
    <WeekPicker date={selectedDate} onChange={setSelectedDate} events={events} />
  </Box>
);
