import { isArray } from 'lodash';
import {
  CheckboxGroupFormFieldType,
  EmailFormFieldType,
  FormFieldBaseType,
  FormFieldType,
  InputFormFieldType,
  PhoneFormFieldType,
  RadioGroupFormFieldType,
  SelectFormFieldType,
  TextAreaFormFieldType
} from './types';

export const isInputFormField = (field: FormFieldBaseType): field is InputFormFieldType =>
  field.element === FormFieldType.Input;

export const isRadioGroupFormField = (field: FormFieldBaseType): field is RadioGroupFormFieldType =>
  isInputFormField(field) && field.type === 'radio' && isArray(field.options);

export const isCheckboxGroupFormField = (field: FormFieldBaseType): field is CheckboxGroupFormFieldType =>
  isInputFormField(field) && field.type === 'checkbox' && isArray(field.options);

export const isTextAreaFormField = (field: FormFieldBaseType): field is TextAreaFormFieldType =>
  field.element === FormFieldType.TextArea;

export const isSelectFormField = (field: FormFieldBaseType): field is SelectFormFieldType =>
  field.element === FormFieldType.Select && isArray(field.options);

export const isPhoneNumberFormField = (field: FormFieldBaseType): field is PhoneFormFieldType =>
  isInputFormField(field) && field.type === 'tel';

export const isEmailFormField = (field: FormFieldBaseType): field is EmailFormFieldType =>
  isInputFormField(field) && field.type === 'email';

export const validationPattern = {
  email: {
    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    message: 'Invalid email address'
  },
  phone: {
    value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
    message: 'Invalid phone number'
  }
};
