import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Pagination, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useCommonApi } from '../api';

import { FlexBlock, FlexCenter, PageLayout, RouterButton } from './common';
import { useRequestsFilter, useUserProfile } from './hooks';
import { RequestActions, RequestFilters, RequestsTable } from './requests';

interface RequestsPageProps {
  createNewPath: string;
}

export const RequestsPage = ({ createNewPath }: RequestsPageProps) => {
  const { role } = useUserProfile();
  const { data = [], isPending } = useQuery(useCommonApi().getRequests());
  const { items: requests, pagination, sort, filters } = useRequestsFilter(data);

  return (
    <PageLayout
      title="Requests"
      withContentBlock
      extra={
        <RouterButton variant="contained" color="primary" to={createNewPath}>
          New Request
        </RouterButton>
      }
    >
      <Stack spacing={2} height={1}>
        <RequestFilters sort={sort} filters={filters} />
        {requests.length || isPending ? (
          <RequestsTable
            requests={requests}
            renderAction={({ status, id }, onActionSuccess) => (
              <RequestActions role={role} status={status} requestId={id} onActionSuccess={onActionSuccess} />
            )}
            loading={isPending}
          />
        ) : (
          <FlexCenter sx={{ maxHeight: 0.5 }}>
            <Stack spacing={1} alignItems="center">
              <ErrorOutlineOutlinedIcon />
              <Typography variant="body1" align="center">
                No data found
              </Typography>
            </Stack>
          </FlexCenter>
        )}
        <FlexBlock />
        {pagination.totalPages > 1 && (
          <Pagination
            count={pagination.totalPages}
            page={pagination.page}
            variant="outlined"
            shape="rounded"
            onChange={(_, page) => pagination.setPage(page)}
          />
        )}
      </Stack>
    </PageLayout>
  );
};
