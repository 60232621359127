import { Avatar, Box, Divider, List, ListItem, ListProps, Stack } from '@mui/material';
import React from 'react';
import { FormattedDate } from 'react-intl';
import { ArticleTags } from '../ArticlesPage';
import { HOVER_BOX_SX, LineClamp } from '../common';
import { Article } from '../types';

interface ArticlesListProps {
  articles: Article[];
  onClick?: (articleId: Article['id']) => void;
  slotProps?: {
    listProps?: Partial<ListProps>;
  };
}

export const ArticlesList = ({ articles, onClick, slotProps: { listProps } = {} }: ArticlesListProps) => (
  <List sx={{ width: 1, bgcolor: 'background.paper', ...listProps?.sx }} {...listProps}>
    {articles.map((article, idx) => {
      const { id: articleId, title, image } = article;
      return (
        <React.Fragment key={articleId}>
          <ListItem
            sx={{
              paddingX: 1,
              ...HOVER_BOX_SX
            }}
            onClick={() => onClick?.(articleId)}
          >
            <Stack spacing={1}>
              <Stack spacing={2} direction="row">
                <Avatar variant="rounded" src={image} sx={{ width: 56, height: 56 }} alt={title}>
                  {title[0]}
                </Avatar>
                <Stack spacing={1} justifyContent="center">
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Box color="text.secondary" typography="body2">
                      <FormattedDate value={article.createdDate} month="short" day="numeric" year="numeric" />
                    </Box>
                    <ArticleTags article={article} />
                  </Stack>
                  <LineClamp maxLines={1} sx={{ fontWeight: 'bold' }}>
                    {title}
                  </LineClamp>
                </Stack>
              </Stack>
            </Stack>
          </ListItem>
          {idx < articles.length - 1 && <Divider />}
        </React.Fragment>
      );
    })}
  </List>
);
