import { Button, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useCommonApi } from '../../api';
import { PATHS } from '../../paths';
import { WidgetLayout, WidgetLayoutProps } from '../common';
import { RequestStatus } from '../types';

interface RequestsWidgetProps extends Omit<WidgetLayoutProps, 'header'> {
  pendingCount?: number;
  onNewRequest?: () => void;
  onShowAll?: () => void;
}

const RequestsWidget = ({ pendingCount = 0, onNewRequest, onShowAll, ...props }: RequestsWidgetProps) => (
  <WidgetLayout
    header="My pending requests"
    sx={{ backgroundColor: 'background.header', color: 'common.white' }}
    actions={
      <Stack spacing={1} width={1}>
        {onNewRequest ? (
          <Button variant="contained" onClick={onNewRequest}>
            New Request
          </Button>
        ) : undefined}
        {onShowAll ? (
          <Button variant="outlined" color="inherit" onClick={onShowAll}>
            Show all requests
          </Button>
        ) : undefined}
      </Stack>
    }
    {...props}
  >
    <Typography variant="body2" typography="h4">
      {pendingCount}
    </Typography>
  </WidgetLayout>
);

export const RequestsWidgetConnected = () => {
  const navigate = useNavigate();
  const { data: requests } = useQuery(useCommonApi().getRequests());
  const pendingRequestsCount = requests?.filter((request) => request.status === RequestStatus.Pending).length;

  return (
    <RequestsWidget
      pendingCount={pendingRequestsCount}
      onNewRequest={() => navigate(PATHS.CREATE_REQUEST)}
      onShowAll={() => navigate(PATHS.REQUESTS)}
    />
  );
};
