import { UserQuestionnaire } from './questionnaries';

export interface UserEventInvitee {
  id: number;
  name: string;
  surname: string;
  status: EventStatus | null;
}

export interface UserEvent {
  id: string;
  title: string;
  dateFrom: Date;
  dateTo: Date;
  place?: string;
  isNew?: boolean;
  content: string | TrustedHTML;
  status: EventStatus | null;
  invitees: UserEventInvitee[];
  questionnaire?: UserQuestionnaire;
}

export enum EventStatus {
  Attend = 'attend',
  Decline = 'decline'
}
