import { Box, BoxProps, SxProps } from '@mui/material';

export const HOVER_BOX_SX: SxProps = {
  backgroundColor: 'background.paper',
  '&:hover': { backgroundColor: 'grey.200', cursor: 'pointer' }
};

export const HoverBox = ({ sx, ...props }: BoxProps) => (
  <Box
    padding={1}
    sx={{
      ...HOVER_BOX_SX,
      ...sx
    }}
    {...props}
  />
);
