import { createTheme } from '@mui/material/styles';

export const BTLNetTheme = createTheme({
  palette: {
    background: {
      default: '#F6F7F9',
      paper: '#ffffff',
      header: '#003481'
    },
    primary: {
      main: '#0083BF'
    },
    secondary: {
      main: '#242424'
    },
    text: {
      primary: '#222234'
    },
    success: {
      main: '#009006',
      light: '#3DBF00'
    },
    warning: {
      main: '#FCA311'
    }
  },
  typography: {
    fontFamily: 'Wix Madefor Text, sans-serif',
    body0: {
      fontSize: '1.25rem',
      lineHeight: 1.333333333333333
    },
    body3: {
      fontSize: '0.775rem',
      lineHeight: 1
    },
    h5: {
      fontWeight: 500
    }
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '4px'
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          borderRadius: '4px',
          padding: 0,
          fontSize: '0.7rem',
          minWidth: '18px',
          height: '18px'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        sx: {
          textTransform: 'none',
          fontWeight: 500
        }
      },
      styleOverrides: {
        sizeLarge: {
          padding: '12px 38px'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '0.9rem'
        }
      }
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: true,
        sx: {
          textTransform: 'none',
          fontWeight: 500,
          border: 'none',
          color: 'text.primary'
        }
      },
      styleOverrides: {
        sizeMedium: {
          padding: '6px 16px'
        }
      }
    },
    MuiCard: { styleOverrides: { root: { padding: '10px' } } }
  }
});
