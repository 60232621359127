import { useQuery } from '@tanstack/react-query';
import { isEmpty, orderBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { ArticlesList } from '.';
import { useCommonApi } from '../../api';
import { PATHS } from '../../paths';
import { RouterButton, WidgetLayout, WidgetLayoutProps } from '../common';
import { NoDataFound } from '../common/NoDataFound';
import { Article } from '../types';

export interface ArticlesWidgetProps extends Omit<WidgetLayoutProps, 'header'> {
  articles: Article[];
  onArticleDetail: (articleId: string) => void;
}

export const ArticlesWidget = ({ articles, onArticleDetail, ...props }: ArticlesWidgetProps) => (
  <WidgetLayout
    header="Articles"
    actions={
      <RouterButton variant="outlined" color="secondary" to={PATHS.ARTICLES}>
        See all
      </RouterButton>
    }
    {...props}
  >
    {!isEmpty(articles) ? (
      <ArticlesList
        articles={articles}
        onClick={onArticleDetail}
        slotProps={{ listProps: { sx: { marginLeft: -1 } } }}
      />
    ) : (
      <NoDataFound />
    )}
  </WidgetLayout>
);

export const ArticlesWidgetConnected = () => {
  const navigate = useNavigate();
  const { data: articles = [] } = useQuery(useCommonApi().getArticles({ limit: 4 }));
  const sortedData = orderBy(articles, 'createdDate', 'desc');

  return (
    <ArticlesWidget articles={sortedData} onArticleDetail={(articleId) => navigate(`${PATHS.ARTICLES}/${articleId}`)} />
  );
};
