import { LoadingButton } from '@mui/lab';
import { Stack, StackProps, SvgIcon } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Check, X } from 'untitledui-js-base';
import { useCommonApi } from '../../api';
import { RequestStatus, Role, UserRequest } from '../types';

export interface RequestActionsProps {
  requestId: UserRequest['id'];
  role: Role;
  status: UserRequest['status'];
  onActionSuccess?: () => void;
  loading?: boolean;
  containerProps?: Partial<StackProps>;
}

interface ManagerRequestActionsProps extends Omit<RequestActionsProps, 'role' | 'requestId'> {
  onDeclined: () => void;
  onApproved: () => void;
}
const ManagerRequestActions = ({
  status,
  onDeclined,
  onApproved,
  loading,
  containerProps
}: Omit<ManagerRequestActionsProps, 'role' | 'requestId'>) => {
  const approveDisabled = status === RequestStatus.Approved;
  const declineDisabled = status === RequestStatus.Declined;
  return (
    <Stack direction="row" spacing={1} {...containerProps}>
      <LoadingButton
        variant="outlined"
        color="secondary"
        size="small"
        loading={loading}
        loadingPosition="start"
        startIcon={
          <SvgIcon color={approveDisabled ? 'inherit' : 'success'}>
            <Check size="1.5rem" />
          </SvgIcon>
        }
        disabled={approveDisabled}
        onClick={() => onApproved()}
      >
        Approve
      </LoadingButton>
      <LoadingButton
        variant="outlined"
        color="secondary"
        size="small"
        loading={loading}
        loadingPosition="start"
        startIcon={
          <SvgIcon color={declineDisabled ? 'inherit' : 'error'}>
            <X size="1.5rem" />
          </SvgIcon>
        }
        disabled={declineDisabled}
        onClick={() => onDeclined()}
      >
        Decline
      </LoadingButton>
    </Stack>
  );
};

interface UserRequestActionsProps extends Omit<RequestActionsProps, 'role' | 'requestId'> {
  onCanceled: () => void;
}
const UserRequestActions = ({ status, onCanceled, loading, containerProps }: UserRequestActionsProps) => {
  const disabled = status !== RequestStatus.Pending;
  return (
    <Stack direction="row" spacing={2} {...containerProps}>
      <LoadingButton
        variant="outlined"
        color="secondary"
        size="small"
        loading={loading}
        loadingPosition="start"
        startIcon={
          <SvgIcon color={disabled ? 'inherit' : 'error'}>
            <X size="1.5rem" />
          </SvgIcon>
        }
        disabled={disabled}
        onClick={() => onCanceled()}
      >
        Cancel
      </LoadingButton>
    </Stack>
  );
};

export const RequestActions = ({ role, requestId, onActionSuccess, ...props }: RequestActionsProps) => {
  const { mutate: setRequestStatus, isPending } = useMutation(useCommonApi().setRequestStatus());
  const { mutate: cancelRequest, isPending: isCanceling } = useMutation(useCommonApi().cancelRequest());

  const decline = () => setRequestStatus({ requestId, status: RequestStatus.Declined }, { onSuccess: onActionSuccess });
  const approve = () => setRequestStatus({ requestId, status: RequestStatus.Approved }, { onSuccess: onActionSuccess });
  const cancel = (requestId: UserRequest['id']) => cancelRequest({ requestId }, { onSuccess: onActionSuccess });

  if (role === Role.User)
    return <UserRequestActions {...props} onCanceled={() => cancel(requestId)} loading={isCanceling} />;

  if (role === Role.HeadOffice)
    return <ManagerRequestActions {...props} onDeclined={decline} onApproved={approve} loading={isPending} />;

  return null;
};
