export enum Role {
  HeadOffice = 'head-office',
  User = 'user'
}

export interface User {
  id: string;
  name: string;
  fullName: string;
  email: string;
  avatarSrc?: string;
  role: Role;
}
