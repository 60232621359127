import { PaginationOptions } from '@jgereg/paginated';
import { useCallback, useMemo } from 'react';
import { SortOption, SortType, useSortingState } from '../../hooks/useSortingState';
import { Article } from '../types';

import { first, orderBy } from 'lodash';
import { usePagination } from '../../hooks/usePagination';
import { useTableFilters } from '../../hooks/useTableFilters';
import { ArticleFiltersType } from '../articles';

export interface FilterSortPaginated<Data, FilterType> {
  items: Data[];
  filters: FiltersType<FilterType>;
  sort: SortActions<Data>;
  pagination: {
    totalRecord: number;
    totalPages: number;
    pageSize: number;
    page: number;
    setPage: (page: number | 'first' | 'last') => void;
  };
}

export interface FiltersType<T = any> {
  filters: T;
  setFilters: (filters: T) => void;
}

export interface SortActions<T = any> {
  toggleSort: (field: keyof T) => void;
  getSortOrder: (field: keyof T) => SortType | undefined;
}

export const useArticlesFilter = (
  data: Article[],
  {
    defaultSortState = { field: 'createdDate', order: SortType.Desc },
    defaultPaginationState = { page: 1, pageSize: 10 }
  }: Partial<{
    defaultSortState: SortOption<Article>;
    defaultPaginationState: PaginationOptions;
  }> = {}
): FilterSortPaginated<Article, ArticleFiltersType> => {
  const {
    data: filteredData,
    filters,
    setFilter: setArticleFilter
  } = useTableFilters<Article, ArticleFiltersType>(
    data,
    [
      (item, { search }) => (search !== '' ? item.title.toLowerCase().includes(search.toLowerCase()) : undefined),
      (item, { isMandatory }) => (isMandatory ? item.isMandatory === true : undefined)
    ],
    {
      defaultFilterState: {
        search: ''
      }
    }
  );

  const { sortState, toggle, getSortOrder } = useSortingState<Article>([defaultSortState]);

  const sortedData = useMemo(() => {
    let result: Article[] = filteredData;
    const sort = first(sortState);
    if (sort) {
      result = orderBy(result, sort.field, sort.order === SortType.Asc ? 'asc' : 'desc');
    }
    return result;
  }, [sortState, filteredData]);

  const { items, pagination, resetPagination, setPage } = usePagination(sortedData, defaultPaginationState);

  const handleSortChange = useCallback(
    (field: keyof Article) => {
      resetPagination();
      toggle(field);
    },
    [resetPagination, toggle]
  );

  const handleFilterChange = useCallback(
    (filters: ArticleFiltersType) => {
      resetPagination();
      setArticleFilter(filters);
    },
    [resetPagination, setArticleFilter]
  );

  return {
    items,
    pagination: {
      ...pagination,
      setPage
    },
    filters: {
      filters,
      setFilters: handleFilterChange
    },
    sort: {
      getSortOrder,
      toggleSort: handleSortChange
    }
  };
};
