import { Box, IconButton, Modal, ModalProps, Stack, Typography } from '@mui/material';
import { FormattedDate } from 'react-intl';
import { X } from 'untitledui-js-base';
import { FormattedAnswers, UserName } from '../common';
import { UserRequest } from '../types';
import { RequestStatusChip, RequestsTableProps } from './RequestsTable';

interface RequestDetailModalProps extends Omit<ModalProps, 'open' | 'children'> {
  handleClose: () => void;
  data: UserRequest;
  renderAction: RequestsTableProps['renderAction'];
}

export const RequestDetailModal = ({ handleClose, data, renderAction }: RequestDetailModalProps) => {
  const { status, title, createdDate, userName, answers } = data;
  return (
    <Modal open={Boolean(data)} onClose={handleClose} disableAutoFocus>
      <Stack
        spacing={3}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          width: 800,
          maxWidth: '90vw',
          boxShadow: 24,
          paddingX: { xs: 2, md: 4 },
          paddingTop: 2,
          paddingBottom: 4
        }}
      >
        <IconButton onClick={handleClose} color="inherit" sx={{ position: 'absolute', right: '10px', top: '10px' }}>
          <X />
        </IconButton>
        <Typography variant="h5" component="h3">
          {title}
        </Typography>
        <Box>{renderAction?.(data, handleClose)}</Box>
        <Box padding={2} sx={{ borderTop: '1px solid', borderBottom: '1px solid', borderColor: 'divider' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" overflow="auto" gap={3}>
            <Stack direction="row" spacing={5} alignItems="center">
              <Box color="text.secondary" typography="body2" whiteSpace="nowrap">
                <FormattedDate value={createdDate} month="short" day="numeric" year="numeric" />
              </Box>
              <RequestStatusChip status={status} />
            </Stack>
            <UserName name={userName} avatar />
          </Stack>
        </Box>
        <FormattedAnswers answers={answers} />
      </Stack>
    </Modal>
  );
};
