import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, BoxProps, IconButton, Stack, StackProps, Typography, TypographyProps } from '@mui/material';
import { isString } from 'lodash';
import { ForwardedRef, ReactNode, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FlexBlock } from '../common';

export const PageContainer = forwardRef<HTMLDivElement, any>(({ sx = {}, ...props }: StackProps, ref) => (
  <Stack
    ref={ref}
    padding={{ xs: 2, md: 4 }}
    spacing={3}
    {...props}
    sx={{ height: '100%', maxWidth: '100%', marginX: 'auto', ...sx }}
  />
));

export const PageTitle = (props: TypographyProps) => <Typography variant="h1" typography="h5" {...props} />;

export interface PageHeaderProps extends Omit<BoxProps, 'title'> {
  backPath?: string;
  title: React.ReactNode | string;
}

export const PageHeader = ({ backPath, title, children, ...props }: PageHeaderProps) => {
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="row" height="40px" minHeight="40px" gap={1} alignItems="center" {...props}>
      {backPath && (
        <IconButton size="small" onClick={() => navigate(backPath)}>
          <ArrowBackIosNewIcon />
        </IconButton>
      )}
      {isString(title) ? <PageTitle>{title}</PageTitle> : title}
      <FlexBlock />
      {children}
    </Box>
  );
};

export const ScrollableContentBlock = ({ sx = {}, ...props }: BoxProps) => (
  <Box sx={{ height: 1, width: 1, overflow: 'auto', ...sx }} {...props} />
);

export const PageContentBlock = ({ sx = {}, ...props }: BoxProps) => (
  <ScrollableContentBlock sx={{ padding: { xs: 1, md: 3 }, backgroundColor: 'background.paper', ...sx }} {...props} />
);

export interface PageLayoutProps extends Omit<StackProps, 'title'> {
  title?: ReactNode;
  extra?: ReactNode;
  withContentBlock?: boolean;
  backPath?: string;
}

export const PageLayout = forwardRef(
  (
    { title, children, withContentBlock, backPath, extra, ...props }: PageLayoutProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <PageContainer {...props} ref={ref}>
      <PageHeader title={title} backPath={backPath}>
        {extra}
      </PageHeader>
      <FlexBlock>{withContentBlock ? <PageContentBlock>{children}</PageContentBlock> : children}</FlexBlock>
    </PageContainer>
  )
);
