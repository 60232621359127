import { IconButton, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { addDays, endOfWeek, format, isBefore, startOfMonth, startOfWeek, subDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'untitledui-js-base';
import { useCommonApi } from '../../api';
import { PATHS } from '../../paths';
import { FlexBlock, RouterButton, WidgetHeader, WidgetLayout, WidgetLayoutProps } from '../common';
import { UserEvent } from '../types';
import { WeekCalendar } from './WeekCalendar';

interface EventsCalendarWidgetProps extends Omit<WidgetLayoutProps, 'header'> {
  events?: UserEvent[] | null;
}

const EventsCalendarWidget = (props: EventsCalendarWidgetProps) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState<boolean>(false);

  const handlePrevWeek = () => {
    setSelectedDate((prev) => {
      const newDate = subDays(prev, 7);
      const firstOfMonth = startOfMonth(new Date());
      return isBefore(newDate, firstOfMonth) ? prev : newDate;
    });
  };

  useEffect(() => {
    const firstOfMonth = startOfMonth(new Date());
    const newPrevDate = subDays(selectedDate, 7);
    setIsPrevButtonDisabled(isBefore(newPrevDate, firstOfMonth));
  }, [selectedDate]);

  const handleNextWeek = () => {
    setSelectedDate((prev) => addDays(prev, 7));
  };

  const startOfCurrentWeek = startOfWeek(selectedDate);
  const endOfCurrentWeek = endOfWeek(selectedDate);

  return (
    <WidgetLayout
      header={
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center">
          <WidgetHeader>Event Calendar</WidgetHeader>
          <FlexBlock />
          <Stack direction="row" gap={1} alignItems="center">
            <IconButton onClick={handlePrevWeek} color="inherit" size="small" disabled={isPrevButtonDisabled}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="body2" fontWeight={600}>
              {format(startOfCurrentWeek, 'd')} - {format(endOfCurrentWeek, 'd MMMM yyyy')}
            </Typography>
            <IconButton onClick={handleNextWeek} color="inherit" size="small">
              <ChevronRight />
            </IconButton>
          </Stack>
        </Stack>
      }
      actions={
        <RouterButton variant="outlined" color="secondary" to={PATHS.EVENTS}>
          Show all events
        </RouterButton>
      }
      {...props}
    >
      <WeekCalendar selectedDate={selectedDate} events={props.events} setSelectedDate={setSelectedDate} />
    </WidgetLayout>
  );
};

export const EventsCalendarWidgetConnected = () => {
  const { data: events = [] } = useQuery(useCommonApi().getEvents({ dateFrom: startOfMonth(new Date()) }));
  return <EventsCalendarWidget events={events} />;
};
