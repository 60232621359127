import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { first, flatMap, isEmpty } from 'lodash';
import React, { ReactNode, Suspense, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import {
  AlignLeft,
  Bell03,
  BookOpen01,
  CalenderPlus02,
  ChevronRight,
  CodeBrowser,
  File02,
  FileAttachment01,
  FilePlus02,
  FileQuestion01,
  HomeSmile,
  Menu01,
  MessageQuestionCircle,
  PasscodeLock,
  PencilLine,
  UserLeft01
} from 'untitledui-js-base';
import { Notification, NotificationModel, useCommonApi } from '../api';
import { STALE_TIME } from '../api/constants';
import { LocalStorageKey } from '../constants';
import { PATHS } from '../paths';
import { ArticlePage } from './ArticlePage';
import { ArticlesPage } from './ArticlesPage';
import { CreateRequestPage } from './CreateRequestPage';
import { DashboardPage } from './DashboardPage';
import { DocumentsPage } from './DocumentsPage';
import { EventPage } from './EventPage';
import { EventsPage } from './EventsPage';
import { PasswordChangePage } from './PasswordChangePage';
import { QuestionnariesPage } from './QuestionnariesPage';
import { RequestsPage } from './RequestsPage';
import { APP_HEADER_HEIGHT, AppHeader, FlexCenter } from './common';
import { AppContentContainer } from './common/AppContentContainer';
import { AppMenu, AppMenuSection } from './common/AppMenu';
import { useAuth } from './hooks';
import { User } from './types';

interface RouteType {
  title: string;
  path: string;
  icon?: ReactNode;
  render: () => ReactNode;
  hidden?: boolean;
  badge?: React.ReactNode;
}

const routes = {
  general: {
    title: 'General',
    items: [
      {
        title: 'Dashboard',
        path: PATHS.HOME,
        icon: <HomeSmile size="1.25rem" />,
        render: () => <DashboardPage />
      }
    ] as RouteType[]
  },
  info: {
    title: 'Informations',
    items: [
      {
        title: 'Events',
        path: PATHS.EVENTS,
        icon: <CodeBrowser size="1.25rem" />,
        render: () => (
          <Routes>
            <Route path="/" element={<EventsPage eventDetailPath={(eventId) => `${PATHS.EVENTS}/${eventId}`} />} />
            <Route path="/:eventId" element={<EventPage parentPath={PATHS.EVENTS} />} />
          </Routes>
        )
      },
      {
        title: 'Articles',
        path: PATHS.ARTICLES,
        icon: <BookOpen01 size="1.25rem" />,
        render: () => (
          <Routes>
            <Route
              path="/"
              element={<ArticlesPage articleDetailPath={(articleId) => `${PATHS.ARTICLES}/${articleId}`} />}
            />
            <Route path="/:articleId" element={<ArticlePage parentPath={PATHS.ARTICLES} />} />
          </Routes>
        )
      }
    ] as RouteType[]
  },
  forms: {
    title: 'Forms',
    items: [
      {
        title: 'Requests',
        path: PATHS.REQUESTS,
        icon: <File02 size="1.25rem" />,
        render: () => (
          <Routes>
            <Route path="/" element={<RequestsPage createNewPath={`${PATHS.REQUESTS}/create`} />} />
            <Route path="/create" element={<CreateRequestPage parentPath={PATHS.REQUESTS} />} />
          </Routes>
        )
      },
      {
        title: 'Questionnaries',
        path: '/questionnaries',
        icon: <FileQuestion01 size="1.25rem" />,
        render: () => <QuestionnariesPage />
      }
    ] as RouteType[]
  },
  documents: {
    title: 'Documents',
    items: [
      {
        title: 'Documents',
        path: PATHS.DOCUMENTS,
        icon: <FileAttachment01 size="1.25rem" />,
        render: () => (
          <Routes>
            <Route
              path="/:folderId?"
              element={
                <DocumentsPage
                  folderDetailPath={(folderId) => `${PATHS.DOCUMENTS}/${folderId}`}
                  parentPath={PATHS.DOCUMENTS}
                />
              }
            />
          </Routes>
        )
      }
    ] as RouteType[]
  },
  user: {
    title: 'User',
    items: [
      {
        title: 'Change password',
        path: PATHS.CHANGE_PASSWORD,
        render: () => <PasswordChangePage />
      }
    ] as RouteType[]
  }
};

interface BTLNetHeaderTitleProps {
  variant?: 'default' | 'dark';
}

export const BTLNetHeaderTitle = ({ variant = 'default' }: BTLNetHeaderTitleProps) => {
  const { src, color } = useMemo(
    () => ({
      src: variant === 'dark' ? '/BTLLogoDark.png' : '/BTLLogo.png',
      color: variant === 'dark' ? 'text.primary' : 'common.white'
    }),
    [variant]
  );

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {src && <Box component="img" src={src} alt="BTL logo" height="33px" />}
      <Typography
        sx={{
          textTransform: 'uppercase',
          fontWeight: 'bold',
          color,
          display: {
            xs: 'none',
            sm: 'inline-block'
          }
        }}
        typography="body2"
      >
        Information system
      </Typography>
    </Stack>
  );
};

interface UserDropdownMenuProps {
  avatarSrc?: string;
  userName: string;
  onNavigate: (path: string) => void;
}

const UserDropdownMenu = ({ userName, avatarSrc, onNavigate }: UserDropdownMenuProps) => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorElUser) setAnchorElUser(event.currentTarget);
    else setAnchorElUser(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const userInitials = (userName: string) => {
    const [firstName, lastName] = userName.split(' ');
    return `${firstName[0]}${lastName[0]}`;
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" onClick={handleOpenUserMenu} sx={{ cursor: 'pointer' }}>
      <Avatar
        sx={{
          width: 36,
          height: 36,
          fontSize: 'medium',
          fontWeight: 700,
          backgroundColor: '#fff',
          color: 'background.header'
        }}
        src={avatarSrc}
      >
        {userInitials(userName)}
      </Avatar>
      <Box typography="body2" padding={2} fontWeight={500} display={{ xs: 'none', md: 'inline-block' }}>
        {userName}
      </Box>
      <Menu
        sx={{ mt: 3 }}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }
        }}
      >
        <MenuItem onClick={() => onNavigate(PATHS.CHANGE_PASSWORD)}>
          <ListItemIcon
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              color: 'inherit'
            }}
          >
            <PasscodeLock size="20px" />
          </ListItemIcon>
          <ListItemText
            primary="Change password"
            sx={{ ml: 0, overflow: 'hidden', textWrap: 'nowrap', color: 'inherit' }}
            primaryTypographyProps={{ fontWeight: 500 }}
          />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleCloseUserMenu();
            //TODO: this is just a temporary way to log out right now
            localStorage.removeItem(LocalStorageKey.SessionToken);
            window.location.reload();
          }}
        >
          <ListItemIcon
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              color: 'inherit'
            }}
          >
            <UserLeft01 size="20px" />
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            sx={{ ml: 0, overflow: 'hidden', textWrap: 'nowrap', color: 'inherit' }}
            primaryTypographyProps={{ fontWeight: 500 }}
          />
        </MenuItem>
      </Menu>
    </Stack>
  );
};

interface NotificationItemProps {
  notification: Notification;
  onClick?: (id: number, url?: string) => void;
}

const NotificationItem = ({
  notification: { id, createdDate, viewed, objectModel, extraData },
  onClick
}: NotificationItemProps) => {
  const getIconComponent = (type: NotificationModel) => {
    switch (type) {
      case NotificationModel.Event:
        return { icon: <CalenderPlus02 size="1.25rem" />, color: 'success.main' };
      case NotificationModel.Article:
        return { icon: <AlignLeft size="1.25rem" />, color: 'primary.main' };
      case NotificationModel.Document:
        return { icon: <FilePlus02 size="1.25rem" />, color: 'primary.main' };
      case NotificationModel.Request:
        return { icon: <MessageQuestionCircle size="1.25rem" />, color: 'warning.main' };
      case NotificationModel.Questionnaire:
        return { icon: <PencilLine size="1.25rem" />, color: 'error.main' };
      default:
        return { icon: <Bell03 size="20px" />, color: 'warning.main' }; // Default icon and color
    }
  };

  return (
    <MenuItem
      sx={{ paddingY: 2, backgroundColor: !viewed ? 'background.default' : '#fff' }}
      onClick={() => onClick?.(id, extraData?.url)}
    >
      <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
        <Stack spacing={2} direction="row" alignItems="center">
          <Badge
            variant="dot"
            color="error"
            overlap="circular"
            badgeContent=" "
            invisible={!extraData?.isMandatory || viewed}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            sx={{ '& .MuiBadge-badge': { borderRadius: '30px' } }}
          >
            <Avatar
              variant="circular"
              sx={{
                backgroundColor: !viewed ? getIconComponent(objectModel).color : 'grey.light',
                width: 30,
                height: 30
              }}
            >
              {getIconComponent(objectModel).icon}
            </Avatar>
          </Badge>
          <ListItemText
            sx={{ maxWidth: '280px' }}
            primary={extraData?.title}
            secondary={`${objectModel} | ${format(createdDate, 'dd.MM.yyyy HH:mm')}`}
            primaryTypographyProps={{ fontWeight: 700, style: { textWrap: 'pretty' } }}
            secondaryTypographyProps={{ fontSize: '0.75rem' }}
          />
        </Stack>
        <ChevronRight />
      </Stack>
    </MenuItem>
  );
};

interface NotificationPanelProps {
  notifications?: Notification[];
  onClickNotification?: (id: number, url?: string) => void;
}

const NotificationPanel = ({ notifications, onClickNotification }: NotificationPanelProps) => {
  const [anchorElNotification, setAnchorElNotification] = React.useState<null | HTMLElement>(null);

  const handleOpenNotificationPanel = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorElNotification) setAnchorElNotification(event.currentTarget);
    else setAnchorElNotification(null);
  };
  const handleCloseNotificationPanel = () => {
    setAnchorElNotification(null);
  };

  return (
    <>
      <IconButton onClick={handleOpenNotificationPanel}>
        <Badge
          badgeContent={notifications?.filter((notification) => !notification.viewed).length}
          color="warning"
          max={99}
          overlap="circular"
        >
          <NotificationsNoneOutlinedIcon sx={{ color: 'common.white' }} />
        </Badge>
      </IconButton>
      <Menu
        sx={{ mt: 3 }}
        id="notifications-bar"
        anchorEl={anchorElNotification}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElNotification)}
        onClose={handleCloseNotificationPanel}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: { xs: 67, md: 14 },
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }
        }}
      >
        <Box paddingY={1} paddingX={2} minWidth="400px">
          <Typography variant="h6">Notifications</Typography>
        </Box>
        <Divider />
        <Box sx={{ maxHeight: '350px', overflowY: 'auto' }}>
          {!isEmpty(notifications) ? (
            notifications?.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                onClick={(id, url) => {
                  onClickNotification?.(id, url);
                  handleCloseNotificationPanel();
                }}
              />
            ))
          ) : (
            <Box textAlign="center" py={3}>
              <Typography variant="body2">No notifications...</Typography>
            </Box>
          )}
        </Box>
      </Menu>
    </>
  );
};

interface BTLNetAppHeaderProps {
  notifications?: Notification[];
  onClickNotification?: (id: number, url?: string) => void;
  user: User;
  onMenuClick: () => void;
  onNavigate: (path: string) => void;
}

const BTLNetAppHeader = ({
  notifications,
  onClickNotification,
  user: { avatarSrc, fullName: userName },
  onMenuClick,
  onNavigate
}: BTLNetAppHeaderProps) => {
  return (
    <AppHeader
      extra={
        <>
          <NotificationPanel notifications={notifications} onClickNotification={onClickNotification} />
          <UserDropdownMenu userName={userName} avatarSrc={avatarSrc} onNavigate={onNavigate} />
        </>
      }
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <IconButton color="inherit" aria-label="open menu" onClick={onMenuClick} sx={{ display: { md: 'none' } }}>
          <Menu01 />
        </IconButton>
        <BTLNetHeaderTitle />
      </Stack>
    </AppHeader>
  );
};

interface BTLNetAppContentProps {
  sections: AppMenuSection[];
  defaultRoutePath: string;
}

const BTLNetAppContent = ({ sections, defaultRoutePath }: BTLNetAppContentProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const { user: authUser } = useAuth();
  const { data: notifications } = useQuery({
    ...useCommonApi().getNotifications(),
    refetchInterval: STALE_TIME.HALF_MINUTE
  });
  const { mutate: setNotificationStatus } = useMutation(useCommonApi().setNotificationStatus());

  if (authUser === null) return null;

  return (
    <>
      <BTLNetAppHeader
        notifications={notifications}
        onClickNotification={(id, url) => {
          if (url) navigate(url);
          setNotificationStatus({ id });
        }}
        user={authUser}
        onMenuClick={() => setMenuOpen(!menuOpen)}
        onNavigate={(path) => navigate(path)}
      />
      <AppContentContainer display="flex" sx={{ paddingTop: `${APP_HEADER_HEIGHT}px` }}>
        <AppMenu sections={sections} menuOpen={menuOpen} onClose={() => setMenuOpen(false)} />
        <Box sx={{ width: 1 }}>
          <Suspense
            fallback={
              <FlexCenter>
                <CircularProgress />
              </FlexCenter>
            }
          >
            <Routes>
              {flatMap(routes, (route) =>
                route.items.map(({ path, render }) => <Route key={path} path={`${path}/*`} element={render()} />)
              )}
              <Route path="*" element={<Navigate to={defaultRoutePath} replace />} />
            </Routes>
          </Suspense>
        </Box>
      </AppContentContainer>
    </>
  );
};

export const BTLNetApp = () => {
  const defaultRoute = first(routes.general.items);
  if (!defaultRoute) return <>Insufficient permissions.</>;

  const sections: AppMenuSection[] = Object.entries(routes)
    .filter(([key]) => key !== 'user')
    .map(([, { title, items }]) => ({
      title,
      items: items.map(({ title, path, icon, badge }) => [title, path, icon, badge])
    }));

  return <BTLNetAppContent defaultRoutePath={defaultRoute.path} sections={sections} />;
};
