import { Chip, ChipProps, Palette, PaletteColor } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { get } from 'lodash';
import { mix, readableColor } from 'polished';

export interface ColorsPalette
  extends Pick<Palette, 'success' | 'error' | 'warning' | 'info' | 'primary' | 'secondary'> {}

const getTransparentChipSx = (palette: Palette, color: keyof ColorsPalette) => {
  const { main, dark, light } = get(palette, color) as PaletteColor;
  const backgroundColor = mix(0.1, main, palette.background.paper);
  return { backgroundColor, color: readableColor(backgroundColor, dark, light, false) };
};

export interface TransparentChipProps extends ChipProps {}
export const TransparentChip = ({ color: colorFromProps = 'default', sx = {}, ...props }: TransparentChipProps) => {
  const { palette } = useTheme();

  return (
    <Chip
      size="small"
      sx={{
        ...sx,
        ...(colorFromProps === 'default' ? {} : getTransparentChipSx(palette, colorFromProps))
      }}
      {...props}
    />
  );
};
