import { useState } from 'react';

export enum SortType {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type SortOption<T> = {
  field: keyof T;
  order: SortType;
};

export const useSortingState = <T>(initialState: SortOption<T>[]) => {
  const [sortState, setSortState] = useState<SortOption<T>[]>(initialState);

  const getSortOrder = (field: keyof T) => {
    const currentSort = sortState.find((sort) => sort.field === field);
    return currentSort ? currentSort.order : undefined;
  };

  const sort = (field: keyof T, order: SortType) => {
    setSortState([{ field, order }]);
  };

  const toggle = (field: keyof T) => {
    setSortState((prevSortState) => {
      const currentSort = prevSortState.find((sort) => sort.field === field);
      if (!currentSort) return [{ field, order: SortType.Asc }];
      const newOrder: SortType = currentSort.order === SortType.Asc ? SortType.Desc : SortType.Asc;
      return [{ field, order: newOrder }];
    });
  };

  return {
    sortState,
    getSortOrder,
    sort,
    toggle
  };
};
