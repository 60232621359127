import { Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { startOfDay } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { FaceContent } from 'untitledui-js-base';
import { useCommonApi } from '../../api';
import { PATHS } from '../../paths';
import { FlexCenter, RouterButton, WidgetLayout, WidgetLayoutProps } from '../common';
import { UserEvent } from '../types';
import { EventItem } from './EventItem';

const NoUpcomingEvent = () => (
  <Stack alignItems="center" justifyContent="center" width={1} gap={2}>
    <FaceContent size="50px" />
    <Typography variant="body2" color="text.secondary">
      No upcoming event scheduled.
    </Typography>
  </Stack>
);

interface EventsWidgetProps extends Omit<WidgetLayoutProps, 'header'> {
  events: UserEvent[];
  onEventClick?: (event: UserEvent) => void;
}
const EventsWidget = ({ events, onEventClick, ...props }: EventsWidgetProps) => (
  <WidgetLayout
    header="Upcoming Events"
    actions={
      events.length > 0 && (
        <RouterButton variant="outlined" color="secondary" to={PATHS.EVENTS}>
          Show all events
        </RouterButton>
      )
    }
    {...props}
  >
    {events.length > 0 ? (
      <Stack>
        {events.map((event) => (
          <EventItem key={event.id} event={event} sx={{ marginLeft: -1 }} onClick={() => onEventClick?.(event)} />
        ))}
      </Stack>
    ) : (
      <FlexCenter>
        <NoUpcomingEvent />
      </FlexCenter>
    )}
  </WidgetLayout>
);

export const EventsWidgetConnected = () => {
  const navigate = useNavigate();
  const { data: events = [] } = useQuery(useCommonApi().getEvents({ dateFrom: startOfDay(new Date()), limit: 2 }));

  return <EventsWidget events={events} onEventClick={({ id: eventId }) => navigate(`${PATHS.EVENTS}/${eventId}`)} />;
};
