import { Stack, StackProps } from '@mui/material';
import { isArray, isEmpty } from 'lodash';
import { FormattedList } from 'react-intl';
import { FormAnswer } from '../types';
import { LabeledProperty, LabeledPropertyLabel, LabeledPropertyValue } from './LabeledProperty';

export interface FormattedAnswerProps {
  value: string | string[];
}
export const FormattedAnswer = ({ value }: FormattedAnswerProps) => {
  if (isEmpty(value)) return <>--</>;
  if (isArray(value)) {
    return <FormattedList value={value} />;
  }
  return <>{value}</>;
};

export interface FormattedAnswersProps {
  answers: FormAnswer[];
  containerProps?: Partial<StackProps>;
}
export const FormattedAnswers = ({ answers, containerProps }: FormattedAnswersProps) => {
  return (
    <Stack spacing={1} maxHeight={600} overflow="auto" {...containerProps}>
      {answers.map(({ key, label, value }) => (
        <LabeledProperty key={key} spacing={2}>
          <LabeledPropertyLabel sx={{ minWidth: 0.4, overflow: 'auto' }}>{label}</LabeledPropertyLabel>
          <LabeledPropertyValue sx={{ overflow: 'auto' }}>
            <FormattedAnswer value={value} />
          </LabeledPropertyValue>
        </LabeledProperty>
      ))}
    </Stack>
  );
};
