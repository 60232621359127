import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, Button, ButtonProps } from '@mui/material';
import { ReactNode } from 'react';
import { SortType } from '../../hooks';

export interface SortButtonProps extends ButtonProps {
  onClick: () => void;
  children: ReactNode;
  direction?: SortType;
}

export const SortButton = ({ onClick, children, direction, ...props }: SortButtonProps) => {
  const getSortDirectionIcon = (direction?: SortType) => {
    const iconStyle = { width: 16, height: 16 };
    if (direction === SortType.Asc) return <ArrowUpwardIcon sx={iconStyle} />;
    if (direction === SortType.Desc) return <ArrowDownwardIcon sx={iconStyle} />;
    return <Box sx={iconStyle} />;
  };

  return (
    <Button
      color="secondary"
      sx={{ whiteSpace: 'nowrap', minWidth: 'auto' }}
      onClick={onClick}
      endIcon={getSortDirectionIcon(direction)}
      {...props}
    >
      {children}
    </Button>
  );
};
