import { Box, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import download from 'downloadjs';
import { FormattedNumber, FormattedPlural } from 'react-intl';
import { useCommonApi } from '../../api';
import { PATHS } from '../../paths';
import { RouterButton, WidgetLayout, WidgetLayoutProps } from '../common';
import { DocumentMeta } from '../types';
import { DocumentsList } from './DocumentsList';

export interface DocumentsWidgetProps extends Omit<WidgetLayoutProps, 'header'> {
  documents: DocumentMeta[];
  totalCount: number;
  onDocumentClick: (document: DocumentMeta) => void;
}

export const DocumentsWidget = ({ documents, totalCount, onDocumentClick, ...props }: DocumentsWidgetProps) => (
  <WidgetLayout
    header="Company documents"
    actions={
      <RouterButton variant="outlined" color="secondary" to={PATHS.DOCUMENTS}>
        Browse documents
      </RouterButton>
    }
    {...props}
  >
    <Box mt={2}>
      <Typography variant="body0" fontWeight="bold">
        <FormattedNumber value={totalCount} />
        &nbsp;
        <FormattedPlural value={totalCount} one="document" other="documents" />
      </Typography>
    </Box>
    <DocumentsList
      documents={documents}
      onClick={onDocumentClick}
      slotProps={{ listProps: { sx: { marginLeft: -1 } } }}
    />
  </WidgetLayout>
);

export const DocumentsWidgetConnected = () => {
  const {
    data: { totalCount, documents } = { totalCount: 0, documents: [] }
  } = useQuery(useCommonApi().getRecentDocuments());

  return (
    <DocumentsWidget
      totalCount={totalCount}
      documents={documents}
      onDocumentClick={(document) => download(document.url)}
    />
  );
};
