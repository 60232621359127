import { useState } from 'react';
import { FilterFunc } from './useFilters';

interface UseFilterOptions<FilterState = any> {
  defaultFilterState: FilterState;
}

type TableFilters<Data = any, FilterState = any> = (
  item: Parameters<FilterFunc<Data>>[0],
  filterState: FilterState
) => boolean | undefined;

export const useTableFilters = <Data, FilterState>(
  data: Data[],
  filters: TableFilters<Data, FilterState>[],
  { defaultFilterState }: UseFilterOptions<FilterState>
) => {
  const [filterState, setFilterState] = useState<FilterState>(defaultFilterState);
  const filteredData = filterState
    ? data.filter((item) => filters.every((filter) => filter(item, filterState) !== false))
    : data;

  return {
    data: filteredData,
    filters: filterState,
    setFilter: setFilterState
  };
};
