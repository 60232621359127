export const PATHS = {
  HOME: '/',
  REQUESTS: '/requests',
  CREATE_REQUEST: '/requests/create',
  ARTICLES: '/articles',
  ARTICLE: '/articles/:articleId',
  DOCUMENTS: '/documents',
  EVENTS: '/events',
  CHANGE_PASSWORD: '/reset-password'
};
