import { Box, BoxProps } from '@mui/material';

export interface AppContentContainerProps extends BoxProps<'main'> {
  offset?: number;
}

export const AppContentContainer = ({ sx = {}, offset, ...props }: AppContentContainerProps) => (
  <Box
    component="main"
    sx={{
      flexGrow: 1,
      backgroundColor: 'background.default',
      minHeight: '100vh',
      position: 'relative',
      ...sx
    }}
    {...props}
  />
);
