import { Global, ThemeProvider } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, CssBaseline, IconButton } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { BTLNetApp } from './app';
import { Auth } from './app/Auth';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { Locale, setDefaultOptions } from 'date-fns';
import { enGB } from 'date-fns/locale/en-GB';
import { FlexCenter } from './app/common';
import { AuthProvider } from './app/hooks';
import reportWebVitals from './reportWebVitals';
import { BTLNetTheme, globalStyles } from './styles';

const root = createRoot(document.getElementById('root') as HTMLElement);

const SnackbarCloseButton = ({ snackbarKey }: { snackbarKey: SnackbarKey }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton size="small" onClick={() => closeSnackbar(snackbarKey)}>
      <CloseIcon sx={{ color: 'common.white' }} />
    </IconButton>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1
    }
  }
});

const Root = () => (
  <Suspense
    fallback={
      <FlexCenter sx={{ height: '100vh', width: '100vh' }}>
        <CircularProgress />
      </FlexCenter>
    }
  >
    <AuthProvider>
      <Auth>
        <BTLNetApp />
      </Auth>
    </AuthProvider>
  </Suspense>
);
const router = createBrowserRouter([{ path: '*', Component: Root }]);
const locale: Locale = enGB;

setDefaultOptions({ locale });

root.render(
  <React.StrictMode>
    <IntlProvider locale={locale.code}>
      <ThemeProvider theme={BTLNetTheme}>
        <QueryClientProvider client={queryClient}>
          <CssBaseline enableColorScheme />
          <Global styles={globalStyles} />
          <SnackbarProvider
            maxSnack={2}
            action={(key) => <SnackbarCloseButton snackbarKey={key} />}
            preventDuplicate
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
              <RouterProvider router={router} />
            </LocalizationProvider>
          </SnackbarProvider>

          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </IntlProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
