import { ReactNode } from 'react';
import { LoginPage } from './LoginPage';
import { useAuth } from './hooks';

export interface AuthProps {
  children?: ReactNode;
}

export const Auth = ({ children }: AuthProps) => {
  const { user, login, isLoggingIn, isResetingPassword, resetPassword, userActionConfirm } = useAuth();

  if (user === null) {
    return (
      <LoginPage
        onLogin={login}
        isLoggingIn={isLoggingIn}
        isResetingPassword={isResetingPassword}
        resetPassword={resetPassword}
        userActionConfirm={userActionConfirm}
      />
    );
  }

  return <>{children}</>;
};
