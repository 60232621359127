import { Pagination, Stack, SvgIcon, ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import download from 'downloadjs';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { Grid01, List } from 'untitledui-js-base';
import { useCommonApi } from '../api';
import { DocumentsViewMode, LocalStorageKey } from '../constants';
import { FlexBlock, PageLayout } from './common';
import { DocumentFilters } from './documents/DocumentFilters';
import { FileNodeList } from './documents/FileNodeList';
import { useUserProfile } from './hooks';
import { useDocumentsFilter } from './hooks/useDocumentsFilter';

interface ListGridToggleProps extends Omit<ToggleButtonGroupProps, 'value' | 'onChange'> {
  value?: DocumentsViewMode;
  onChange?: (view: DocumentsViewMode) => void;
}

const ListGridToggle = ({ value, onChange, ...props }: ListGridToggleProps) => (
  <ToggleButtonGroup
    size="medium"
    value={value}
    exclusive
    {...props}
    onChange={(_, view) => onChange?.(view as DocumentsViewMode)}
  >
    <ToggleButton value="grid">
      <SvgIcon sx={{ mr: 1 }}>
        <Grid01 />
      </SvgIcon>
      Grid
    </ToggleButton>
    <ToggleButton value="list">
      <SvgIcon sx={{ mr: 1 }}>
        <List />
      </SvgIcon>
      List
    </ToggleButton>
  </ToggleButtonGroup>
);

interface DocumentsPageProps {
  folderId?: string;
  parentPath?: string;
  folderDetailPath: (folderId: string) => string;
}

const DocumentsPageContent = ({ folderId, parentPath, folderDetailPath }: DocumentsPageProps) => {
  const navigate = useNavigate();
  const { role } = useUserProfile();

  const {
    data: { folders = [], documents: allDocuments = [], folderName, parentId: folderParentId },
    isPending
  } = useSuspenseQuery(useCommonApi().getDocuments(folderId));

  const { mutate: uploadDocuments, isPending: isUploading } = useMutation(useCommonApi().uploadDocuments());
  const { mutate: renameDocument } = useMutation(useCommonApi().editDocument());
  const { mutate: removeDocument } = useMutation(useCommonApi().removeDocument());

  const [documentsViewMode, setDocumentsViewMode] = useLocalStorage<DocumentsViewMode>(
    LocalStorageKey.DocumentsViewMode,
    'grid'
  );

  const {
    items: documents,
    pagination,
    filters
  } = useDocumentsFilter(allDocuments, { defaultPaginationState: { pageSize: 16, page: 1 } });

  const getParentPath = (folderParentId: string) =>
    folderParentId === 'root' ? parentPath : folderDetailPath(folderParentId);
  //const hasUploadButtonInHeader = role === Role.HeadOffice;

  return (
    <PageLayout
      title={folderName ? folderName : 'Documents'}
      backPath={folderParentId ? getParentPath(folderParentId) : undefined}
      spacing={2}
      /*extra={
              hasUploadButtonInHeader ? (
                <UploadButton
                  multiple
                  onUpload={(formData) => uploadDocuments({ formData, folderId })}
                  disabled={isUploading}
                >
                  Upload documents
                </UploadButton>
              ) : undefined
            }*/
    >
      <Stack spacing={2} height={1}>
        <Stack direction="row" alignItems="center">
          <DocumentFilters filters={filters} disabled={isPending} />
          <FlexBlock />
          <ListGridToggle value={documentsViewMode} onChange={setDocumentsViewMode} />
        </Stack>
        <FileNodeList
          viewMode={documentsViewMode}
          folders={folders}
          documents={documents}
          onFolderSelect={(folder) => navigate(folderDetailPath(folder.id))}
          onDocumentSelect={(document) => download(document.url)}
          documentMenuProps={{
            onDownload: (document) => download(document.url),
            onRemove: (document) => removeDocument({ documentId: document.id })
            //TODO: onEdit: (document) => renameDocument({documentId: document.id, name: 'new name'}),
          }}
        />
        <FlexBlock />
        {pagination.totalPages > 1 && (
          <Pagination
            count={pagination.totalPages}
            page={pagination.page}
            variant="outlined"
            shape="rounded"
            onChange={(_, page) => pagination.setPage(page)}
          />
        )}
      </Stack>
    </PageLayout>
  );
};

export const DocumentsPage = (props: Omit<DocumentsPageProps, 'articleId'>) => {
  const { folderId } = useParams<{ folderId: string }>();

  return <DocumentsPageContent folderId={folderId} {...props} />;
};
