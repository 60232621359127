import { LoadingButton } from '@mui/lab';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { ReactNode } from 'react';
import { FormattedDate } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Check } from 'untitledui-js-base';
import { useCommonApi } from '../api';
import { ArticleTags } from './ArticlesPage';
import { FlexBlock, PageLayout, PageLayoutProps, PageTitle } from './common';
import { Article } from './types';

interface ArticlePageLayoutProps extends PageLayoutProps {
  title?: ReactNode;
  article: Article;
  extra?: ReactNode;
  parentPath: string;
}

const ArticlePageLayout = ({ title, article, parentPath, children, extra, ...props }: ArticlePageLayoutProps) => (
  <PageLayout
    title={
      // we are expecting h1 elements in article content so the title should be h2 for this layout
      <PageTitle variant="h2">Articles</PageTitle>
    }
    backPath={parentPath}
    withContentBlock
    {...props}
  >
    <Stack spacing={2} sx={{ height: 1, overflow: 'auto', paddingTop: { xs: 1, md: 6 }, paddingX: { xs: 1, md: 10 } }}>
      <FlexBlock>
        <Stack spacing={2} marginBottom={2}>
          <Typography variant="h1" typography="h4">
            {title}
          </Typography>
          <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={2} alignItems="center">
              <Box color="text.secondary" typography="body2">
                <FormattedDate value={article.createdDate} month="short" day="numeric" year="numeric" />
              </Box>
              <ArticleTags article={article} />
            </Stack>
          </Stack>
        </Stack>
        {children}
      </FlexBlock>
      <Box>{extra}</Box>
    </Stack>
  </PageLayout>
);

interface ArticlePageContentProps {
  articleId: string;
  parentPath: string;
}

const ArticlePageContent = ({ articleId, parentPath }: ArticlePageContentProps) => {
  const { data: article } = useSuspenseQuery(useCommonApi().getArticle(articleId));
  const { mutate: markArticleAsRead, isPending } = useMutation(useCommonApi().markArticleAsRead());

  //TODO: do something when article is not present -> 404 page or navigate out
  if (article === null) {
    return null;
  }

  return (
    <ArticlePageLayout
      title={article.title}
      article={article}
      parentPath={parentPath}
      extra={
        article.isMandatory && (
          <Stack spacing={3}>
            <Divider />
            <LoadingButton
              variant="contained"
              onClick={() => markArticleAsRead(article.id)}
              sx={{ maxWidth: 'fit-content' }}
              startIcon={<Check size="1rem" />}
              loadingPosition="start"
              loading={isPending}
            >
              Mark as read
            </LoadingButton>
          </Stack>
        )
      }
    >
      {article.image && (
        <Box component="img" src={article.image} sx={{ maxHeight: '400px', maxWidth: '100%', width: 'auto' }} />
      )}
      <Box
        component="div"
        dangerouslySetInnerHTML={{ __html: article.htmlContent }}
        sx={{ img: { maxWidth: '100%' }, p: { wordBreak: 'break-word' } }}
      />
    </ArticlePageLayout>
  );
};

export const ArticlePage = (props: Omit<ArticlePageContentProps, 'articleId'>) => {
  const { articleId } = useParams<{ articleId: string }>();
  if (!articleId) return null;
  return <ArticlePageContent articleId={articleId} {...props} />;
};
