import { Avatar, Stack, Typography } from '@mui/material';
import { first } from 'lodash';

export interface UserNameProps {
  name: string;
  avatar?: boolean;
}

export const UserName = ({ name, avatar }: UserNameProps) => {
  if (avatar) {
    const firstLetter = first(name);
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Avatar sx={{ height: '1.5rem', width: '1.5rem' }}>
          <Typography>{firstLetter}</Typography>
        </Avatar>
        <Typography whiteSpace="nowrap">{name}</Typography>
      </Stack>
    );
  }

  return <Typography>{name}</Typography>;
};
