import { Box, BoxProps } from '@mui/material';
import { ReactNode } from 'react';
import { FlexBlock } from '../common';

export const APP_HEADER_HEIGHT = 56;

const AppHeaderContainer = ({ sx = {}, ...props }: BoxProps) => (
  <Box
    {...props}
    component="header"
    sx={{
      position: 'fixed',
      right: 0,
      left: 0,
      top: 0,
      alignItems: 'center',
      backgroundColor: 'background.header',
      color: 'common.white',
      display: 'flex',
      flex: '0 0 auto',
      gap: 8,
      height: APP_HEADER_HEIGHT,
      typography: 'body1',
      paddingX: { xs: 2, md: 4 },
      zIndex: 999,
      ...sx
    }}
  />
);

export interface AppHeaderProps {
  extra?: ReactNode;
  children?: ReactNode;
}

export const AppHeader = ({ extra, children }: AppHeaderProps) => (
  <AppHeaderContainer>
    {/* <LogoBlack height={APP_HEADER_HEIGHT - 20} /> */}
    <FlexBlock>{children}</FlexBlock>
    {extra && (
      <Box display="flex" gap={{ xs: 2, md: 5 }} alignItems="center">
        {extra}
      </Box>
    )}
  </AppHeaderContainer>
);
