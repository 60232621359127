import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { FormattedDate } from 'react-intl';
import { fixedCellSize } from '../../utils';
import { TableLoadingContainer, TransparentChip, TransparentChipProps, UserName } from '../common';
import { UserRequest } from '../types';
import { RequestDetailModal } from './RequestDetailModal';
import { requestStatusColors, requestStatusLabels } from './labels';

interface RequestStatusChipProps extends TransparentChipProps {
  status: UserRequest['status'];
}

export const RequestStatusChip = ({ status, ...props }: RequestStatusChipProps) => (
  <TransparentChip
    size="small"
    variant="filled"
    color={requestStatusColors[status]}
    label={requestStatusLabels[status]}
    {...props}
  />
);

export interface RequestsTableProps {
  requests: UserRequest[];
  renderAction: (request: UserRequest, onActionSuccess?: () => void) => React.ReactNode;
  loading?: boolean;
}

export const RequestsTable = ({ requests, renderAction, loading }: RequestsTableProps) => {
  const [selectedRequestId, setSelectedRequestId] = useState<UserRequest['id'] | null>(null);
  const selectedRequest = requests.find(({ id }) => id === selectedRequestId);

  return (
    <>
      <TableLoadingContainer loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={fixedCellSize(120)}>Date</TableCell>
              <TableCell sx={fixedCellSize(90, { padding: 0 })}>Status</TableCell>
              <TableCell sx={fixedCellSize(200)}>User</TableCell>
              <TableCell>Name</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.map((request, idx) => {
              const { status, createdDate, userName, title } = request;
              return (
                <TableRow key={idx}>
                  <TableCell sx={fixedCellSize(120)}>
                    <Box color="text.secondary" typography="body2">
                      <FormattedDate value={createdDate} month="short" day="numeric" year="numeric" />
                    </Box>
                  </TableCell>
                  <TableCell sx={fixedCellSize(90, { padding: 0 })}>
                    <RequestStatusChip status={status} />
                  </TableCell>
                  <TableCell sx={fixedCellSize(200)}>
                    <UserName name={userName} avatar />
                  </TableCell>
                  <TableCell>
                    <Box
                      onClick={() => setSelectedRequestId(request.id)}
                      color="secondary"
                      sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                      typography="body1"
                    >
                      {title}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ width: 'auto' }}>
                    <Box display="flex" justifyContent="flex-end">
                      {renderAction?.(request)}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableLoadingContainer>
      {selectedRequest && (
        <RequestDetailModal
          handleClose={() => setSelectedRequestId(null)}
          data={selectedRequest}
          renderAction={renderAction}
        />
      )}
    </>
  );
};
