import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { FormattedDate } from 'react-intl';
import { fixedCellSize } from '../../utils';
import { TableLoadingContainer, TransparentChip } from '../common';
import { UserQuestionnaire } from '../types';

export interface QuestionnariesTableProps {
  loading?: boolean;
  questionnaries: UserQuestionnaire[];
  renderAction?: (questionnaire: UserQuestionnaire, onActionSuccess?: () => void) => React.ReactNode;
}

export const QuestionnariesTable = ({ loading, questionnaries, renderAction }: QuestionnariesTableProps) => {
  return (
    <TableLoadingContainer loading={loading}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={fixedCellSize(120)}>Date</TableCell>
            <TableCell sx={fixedCellSize(140)}>Event</TableCell>
            <TableCell>Name</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {questionnaries.map((questionnaire, idx) => {
            const { title, createdDate, event } = questionnaire;
            return (
              <TableRow key={idx}>
                <TableCell sx={fixedCellSize(120)}>
                  <Box color="text.secondary" typography="body2">
                    <FormattedDate value={createdDate} month="short" day="numeric" year="numeric" />
                  </Box>
                </TableCell>
                <TableCell sx={fixedCellSize(140, { padding: 0 })}>
                  {event ? <TransparentChip label={event.title} /> : null}
                </TableCell>
                <TableCell>
                  <Typography variant="body2" whiteSpace="nowrap">
                    {title}
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: 'auto' }}>
                  <Box display="flex" justifyContent="flex-end" minWidth={150}>
                    {renderAction?.(questionnaire)}
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableLoadingContainer>
  );
};
