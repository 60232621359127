import { Box, BoxProps, Stack, StackProps } from '@mui/material';
import { FlexBlock, FlexBlockProps } from '../common';

export interface LabeledPropertyLabelProps extends BoxProps {}
export const LabeledPropertyLabel = ({ sx = {}, ...props }: LabeledPropertyLabelProps) => (
  <Box color="text.secondary" sx={{ minWidth: 150, ...sx }} {...props} />
);

export interface LabeledPropertyValueProps extends FlexBlockProps {}
export const LabeledPropertyValue = (props: LabeledPropertyValueProps) => <FlexBlock {...props} />;

interface LabeledPropertyProps extends StackProps {
  children: React.ReactNode;
}
export const LabeledProperty = ({ children, ...props }: LabeledPropertyProps) => (
  <Stack direction="row" alignItems="baseline" {...props}>
    {children}
  </Stack>
);
