export enum DocumentType {
  PDF = 'pdf',
  DOC = 'doc',
  XLS = 'xls',
  PPT = 'ppt',
  Image = 'image'
}

export interface FileNode {
  folderId: string;
  folderName?: string;
  parentId?: 'root' | string;
  folders: FolderMeta[];
  documents: DocumentMeta[];
}

export interface DocumentMeta {
  id: string;
  name: string;
  type?: DocumentType;
  url: string;
}

export interface FolderMeta {
  id: string;
  name: string;
}
