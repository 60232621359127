import Grid from '@mui/material/Unstable_Grid2';
import { isArray } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { useMeasure } from 'react-use';
import { ArticlesWidgetConnected } from './articles';
import { PageLayout } from './common';
import { DocumentsWidgetConnected } from './documents';
import { EventsCalendarWidgetConnected, EventsWidgetConnected } from './events';
import { RequestsWidgetConnected } from './requests';

const DASHBOARD_BREAKPOINTS = {
  SM: 550,
  MD: 900
};

type DashboardLayout = 'small' | 'medium' | 'large';

//GriddedWidget = [component, space to take up in a grid]
type GriddedWidget = [React.ReactNode, number] | React.ReactNode;

const widgetLayout: Record<DashboardLayout, GriddedWidget[][]> = {
  small: [
    [<RequestsWidgetConnected />],
    [<EventsCalendarWidgetConnected />],
    [<EventsWidgetConnected />],
    [<ArticlesWidgetConnected />],
    [<DocumentsWidgetConnected />]
  ],
  medium: [
    [[<EventsCalendarWidgetConnected />, 8], <RequestsWidgetConnected />],
    [<EventsWidgetConnected />],
    [[<ArticlesWidgetConnected />, 7], <DocumentsWidgetConnected />]
  ],
  large: [
    [[<EventsCalendarWidgetConnected />, 5], [<EventsWidgetConnected />, 4], <RequestsWidgetConnected />],
    [[<ArticlesWidgetConnected />, 8], <DocumentsWidgetConnected />]
  ]
};

const getDashboardLayout = (width: number): DashboardLayout => {
  if (width < DASHBOARD_BREAKPOINTS.SM) return 'small';
  if (width < DASHBOARD_BREAKPOINTS.MD) return 'medium';
  return 'large';
};

export const DashboardPage = () => {
  const [measureRef, { width }] = useMeasure<HTMLDivElement>();
  const isContainerReady = width > 0;
  const layout = useMemo(() => getDashboardLayout(width), [width]);
  const container = (content: ReactNode) => (
    <PageLayout ref={measureRef} title="Dashboard">
      {content}
    </PageLayout>
  );

  if (!isContainerReady) return container(null);

  return container(
    <>
      {widgetLayout[layout].map((widgetRow, idx) => (
        <Grid container columnSpacing={2} rowSpacing={4} key={idx}>
          {widgetRow.map((widget, widgetIdx) => {
            const [widgetComponent, size] = !isArray(widget) ? [widget, true] : widget;
            return (
              <Grid xs={size} key={widgetIdx}>
                {widgetComponent}
              </Grid>
            );
          })}
        </Grid>
      ))}
    </>
  );
};
