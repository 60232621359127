import { Theme, css } from '@emotion/react';
import { transparentize } from 'polished';

export const globalStyles = ({ palette: { text, secondary, primary, divider } }: Theme) => css`
  html,
  body,
  #root {
    color: #000;
  }

  a {
    color: ${text.primary};
  }

  // Scrollbar restyles
  body {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px
  }

  ::-webkit-scrollbar-thumb {
    background: transparent; /* Make it transparent initially */
    border-radius: 6px;
  }

  :hover::-webkit-scrollbar-thumb {
    background: ${transparentize(0.9, secondary.main)};
  }


  //TODO: REVIEW - THIS is only for single component DateCalendar in EventsPage
  .MuiDayCalendar-root {
    .MuiDayCalendar-header {
      justify-content: space-around;
    }

    .MuiDayCalendar-monthContainer {
      .MuiDayCalendar-weekContainer {
        margin: 0;

        :last-child {
          .MuiPickersDay-root {
            border-bottom: 1px solid ${divider};
          }
        }

        .MuiPickersDay-custom-wrapper:last-child {
          .MuiPickersDay-root {
            border-right: 1px solid ${divider};
          }
        }

        .MuiPickersDay-root {
          border: 1px solid ${divider};
          border-right: none;
          border-bottom: none;
          font-size: 1rem;

          &.MuiPickersDay-today {
            position: relative;

            &:before {
              content: "";
              width: 10px;
              height: 10px;
              position: absolute;
              top: 10px;
              left: 14px;
              background-color: ${primary.main};
              border-radius: 100%;
            }
          }
        }

        .MuiPickersDay-dayOutsideMonth {
          color: ${text.disabled};
        }
      }
    }
  }

  .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition {
    overflow-x: visible;
  }
`;
