import { LoadingButton } from '@mui/lab';
import { Box, Divider, Stack } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Form, FormType, useCommonApi } from '../api';
import { ScrollableContentBlock } from './common';
import { ControlledInputFormField, DynamicFormRenderer } from './dynamicForm';
import { UserQuestionnaireAnswer } from './types';

export interface EditQuestionnaireProps {
  form: Form;
  defaultAnswers?: UserQuestionnaireAnswer['answers'];
  onSubmit?: (data: Record<string, any>) => void;
}
export const EditQuestionnaire = ({ form, defaultAnswers = [], onSubmit }: EditQuestionnaireProps) => {
  const { mutate: submitForm, isPending } = useMutation(useCommonApi().submitForm());
  const defaultValues = defaultAnswers.reduce((acc, { key, value }) => Object.assign(acc, { [key]: value }), {});

  return (
    <DynamicFormRenderer
      height={1}
      defaultValues={defaultValues}
      onSubmit={(data) => {
        if (form) {
          onSubmit?.(data);
          submitForm({ formId: form.id, formType: FormType.Questionnaire, data: data });
        }
      }}
    >
      {({ control }) => (
        <Stack spacing={5} height={1} sx={{ maxWidth: 1024, margin: '0 auto' }} divider={<Divider />}>
          {form && (
            <ScrollableContentBlock sx={{ height: 'auto' }}>
              <Stack spacing={4} paddingTop={1}>
                {form.content.map((field) => (
                  <ControlledInputFormField key={field.id} control={control} field={field} disabled={isPending} />
                ))}
              </Stack>
            </ScrollableContentBlock>
          )}
          <Box display="flex" justifyContent="flex-end">
            <LoadingButton type="submit" variant="contained" loading={isPending}>
              Submit
            </LoadingButton>
          </Box>
        </Stack>
      )}
    </DynamicFormRenderer>
  );
};

export interface EditQuestionnaireConnectedProps extends Omit<EditQuestionnaireProps, 'form'> {
  formId: string;
}
export const EditQuestionnaireConnected = ({ formId, ...props }: EditQuestionnaireConnectedProps) => {
  const { data: form, isPending } = useQuery(useCommonApi().getForm(formId, FormType.Questionnaire));

  if (isPending) return null;
  if (!form) return <Box>Form not found</Box>;

  return <EditQuestionnaire form={form} {...props} />;
};
