import { InputAdornment, Stack, TextField } from '@mui/material';
import { SearchSM } from 'untitledui-js-base';
import { FiltersType } from '../hooks';

export interface DocumentFiltersType {
  search: string;
}
export interface DocumentFiltersProps {
  filters: FiltersType<DocumentFiltersType>;
  disabled?: boolean;
}
export const DocumentFilters = ({ filters: { filters, setFilters }, disabled }: DocumentFiltersProps) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <TextField
      placeholder="Search in documents"
      variant="outlined"
      value={filters.search}
      size="small"
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchSM color="black" />
          </InputAdornment>
        ),
        sx: {
          backgroundColor: 'background.paper'
        }
      }}
      onChange={(e) => setFilters({ ...filters, search: e.target.value })}
    />
  </Stack>
);
