import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, FormControl, FormHelperText, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { ChangeEventHandler } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useCommonApi } from '../api';
import { PageLayout } from './common';

interface PasswordTextFieldProps {
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  label: string;
  error?: boolean;
}

const PasswordTextField = ({ value, onChange, label, error }: PasswordTextFieldProps) => {
  const [showPassword, onShowPasswordClick] = React.useState(false);

  return (
    <TextField
      onChange={onChange}
      value={value}
      variant="outlined"
      label={label}
      error={error}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        disableUnderline: true,
        sx: {
          backgroundColor: 'background.paper'
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              edge="end"
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => onShowPasswordClick?.(!showPassword)}
            >
              {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export type PasswordChangeFormType = {
  currentPassword: string;
  newPassword: string;
  newPasswordAgain: string;
};

interface PasswordChangePageProps {
  onPasswordChange?: (data: PasswordChangeFormType) => void;
  isLoading?: boolean;
}

const PasswordChangePageContent = ({ onPasswordChange, isLoading }: PasswordChangePageProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<PasswordChangeFormType>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordAgain: ''
    }
  });

  return (
    <Box maxWidth="400px" mt={2}>
      <Stack gap={3} component="form" noValidate onSubmit={handleSubmit((data) => onPasswordChange?.(data))}>
        <FormControl fullWidth>
          <Controller
            name="currentPassword"
            control={control}
            rules={{ required: 'Field is required' }}
            render={({ field }) => (
              <PasswordTextField {...field} label="Current password" error={Boolean(errors.currentPassword)} />
            )}
          />
          {errors.currentPassword && (
            <FormHelperText sx={{ color: 'error.main' }}>{errors.currentPassword.message}</FormHelperText>
          )}
        </FormControl>
        <FormControl fullWidth>
          <Controller
            name="newPassword"
            control={control}
            rules={{ required: 'Field is required' }}
            render={({ field }) => (
              <PasswordTextField {...field} label="New password" error={Boolean(errors.currentPassword)} />
            )}
          />
          {errors.newPassword && (
            <FormHelperText sx={{ color: 'error.main' }}>{errors.newPassword.message}</FormHelperText>
          )}
        </FormControl>
        <FormControl fullWidth>
          <Controller
            name="newPasswordAgain"
            control={control}
            rules={{ required: 'Field is required' }}
            render={({ field }) => (
              <PasswordTextField {...field} label="New password repeat" error={Boolean(errors.currentPassword)} />
            )}
          />
          {errors.newPasswordAgain && (
            <FormHelperText sx={{ color: 'error.main' }}>{errors.newPasswordAgain.message}</FormHelperText>
          )}
        </FormControl>
        <LoadingButton type="submit" variant="contained" size="large" loading={isLoading} loadingPosition="start">
          Change password
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export const PasswordChangePage = () => {
  const { mutate: changePassword, isPending } = useMutation(useCommonApi().changePassword());

  return (
    <PageLayout title="Change Password" withContentBlock>
      <PasswordChangePageContent onPasswordChange={changePassword} isLoading={isPending} />
    </PageLayout>
  );
};
