import { BoxProps, Stack, Typography } from '@mui/material';
import { FormattedDateTimeRange } from 'react-intl';
import { PATHS } from '../../paths';
import { HoverBox, RouterButton, TransparentChip } from '../common';
import { UserEvent } from '../types';

export interface EventItemProps extends BoxProps {
  event: UserEvent;
  showActions?: boolean;
}
export const EventItem = ({
  event: { place, title, dateFrom, dateTo, isNew, id },
  showActions,
  ...props
}: EventItemProps) => (
  <HoverBox {...props}>
    <Stack alignItems="center" justifyContent="space-between" direction="row" width="100%">
      <Stack>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant="body2" color="text.secondary" fontWeight={500}>
            <FormattedDateTimeRange from={dateFrom} to={dateTo} month="short" day="numeric" year="numeric" />
          </Typography>
          {isNew && <TransparentChip size="small" label="New" color="success" variant="filled" />}
        </Stack>
        <Typography variant="body1" fontWeight={700} whiteSpace="normal">
          {title}
        </Typography>
        <Typography variant="body2" fontWeight={500}>
          {place}
        </Typography>
      </Stack>
      {showActions && (
        <RouterButton variant="contained" color="primary" to={`${PATHS.EVENTS}/${id}`}>
          Show Details
        </RouterButton>
      )}
    </Stack>
  </HoverBox>
);
