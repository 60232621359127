import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, FormType, useCommonApi } from '../api';
import { PageContentBlock, PageLayout, PageTitle, ScrollableContentBlock } from './common';
import { ControlledInputFormField, DynamicFormRenderer } from './dynamicForm';
const EMPTY_OPTION_ID = '';

export const CreateRequestHint = () => (
  <Stack spacing={2}>
    <Typography fontWeight="bold" variant="body2">
      Help
    </Typography>
    <Box component="ul" sx={{ gap: 2, display: 'flex', flexDirection: 'column', listStyle: 'outside' }}>
      <Typography component="li" variant="body2">
        Simply create request by filling all input fields
      </Typography>
      <Typography component="li" variant="body2">
        Your request will be answered within 3 days
      </Typography>
    </Box>
  </Stack>
);

interface RequestFormSelectProps {
  selectedFormId: string | '';
  onFormChange: (formId: Form['id']) => void;
  forms: Form[];
  loading?: boolean;
}
const RequestFormSelect = ({ selectedFormId, onFormChange, forms, loading }: RequestFormSelectProps) => (
  <FormControl fullWidth sx={{ backgroundColor: 'background.default' }} color="secondary">
    <InputLabel>Select Request</InputLabel>
    <Select<string>
      label="Select Request"
      value={selectedFormId}
      onChange={(e) => onFormChange(e.target.value as unknown as string)}
      disabled={loading || selectedFormId !== EMPTY_OPTION_ID}
    >
      {forms?.map(({ id, title }) => (
        <MenuItem value={id} key={id}>
          {title}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

interface CreateRequestPageProps {
  parentPath: string;
}
export const CreateRequestPage = ({ parentPath }: CreateRequestPageProps) => {
  const { data: forms } = useQuery(useCommonApi().getForms(FormType.Request));
  const { mutate: submitForm, isPending } = useMutation(useCommonApi().submitForm());
  const navigate = useNavigate();

  const [selectedFormId, setSelectedFormId] = useState<string | ''>(EMPTY_OPTION_ID);
  const selectedForm = forms?.find(({ id }) => id === selectedFormId);

  return (
    <PageLayout
      title={
        <Stack direction="row" alignItems="center" gap={1}>
          <PageTitle>New Request</PageTitle>
        </Stack>
      }
      backPath={parentPath}
    >
      <PageContentBlock>
        <DynamicFormRenderer
          onSubmit={(data) =>
            selectedFormId &&
            submitForm(
              { formId: selectedFormId, formType: FormType.Request, data: data },
              { onSuccess: () => navigate(parentPath) }
            )
          }
          height={1}
        >
          {({ control }) => (
            <Stack spacing={5} height={1} sx={{ maxWidth: 1024, margin: '0 auto' }} divider={<Divider />}>
              <Box display="flex" flexDirection="row" gap={2} alignItems="center">
                <RequestFormSelect
                  selectedFormId={selectedFormId}
                  onFormChange={(requestId) => setSelectedFormId(requestId)}
                  forms={forms || []}
                  loading={isPending}
                />
                <Box sx={{ height: 40, width: 50 }}>
                  {selectedFormId !== EMPTY_OPTION_ID && (
                    <Tooltip title="Reset to default">
                      <IconButton color="error" onClick={() => setSelectedFormId(EMPTY_OPTION_ID)}>
                        <RestartAltIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </Box>
              {selectedForm && (
                <ScrollableContentBlock sx={{ height: 'auto' }}>
                  <Stack spacing={4} paddingTop={1}>
                    {selectedForm.content.map((field) => (
                      <ControlledInputFormField key={field.id} control={control} field={field} disabled={isPending} />
                    ))}
                  </Stack>
                </ScrollableContentBlock>
              )}
              {selectedForm && (
                <Box display="flex" justifyContent="flex-end">
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="large"
                    loading={isPending}
                    loadingPosition="start"
                    startIcon={<ForwardToInboxIcon />}
                  >
                    Send
                  </LoadingButton>
                </Box>
              )}
            </Stack>
          )}
        </DynamicFormRenderer>
      </PageContentBlock>
    </PageLayout>
  );
};
